import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form, Input, Button, DatePicker, Space, Tooltip, Modal,
  Divider, Row, Col, Select, Upload, Descriptions
} from "antd";
import "./jobs.css";
import { PlusOutlined } from "@ant-design/icons";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { checkStaff } from "../../../../util/auth";
import * as helper from "../../../../util/helper";
import * as prop from "../../../../util/properties";
import * as service from "../../../../util/services";

const { Option } = Select;

const createAddressObj = (site) => {
  return (
    <Descriptions column={1} contentStyle={{marginBottom:"-1rem", paddingLeft:"0.5rem"}} style={{paddingLeft:"4.65rem"}}>
      <Descriptions.Item label="Address" style={{paddingBottom:"0"}}>{site.address}</Descriptions.Item>
      <Descriptions.Item label="&emsp;&emsp;&emsp;&ensp;" style={{paddingBottom:"0"}} labelStyle={{color:"transparent"}}>
        {site.addressLine2}
      </Descriptions.Item>
      <Descriptions.Item label="&emsp;&emsp;&emsp;&ensp;" labelStyle={{color:"transparent"}}>
        {site.suburb} {site.state} {site.postcode}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default function CreateNewJob (props) {
  const [ permission, setPermission ] = useState("");
  const [ showSiteModal, setShowSiteModal] = useState(false);
  const [ addressObj, setAddressObj ] = useState(null);
  const [ options, setOptions ] = useState({
    project: [],
    inventory: [],
    projectAdmin: [],
    jobStatus: [],
    zone: [],
    site: [],
  });
  const [ uploadList, setUploadList ] = useState([]);
  const JobsForm = useRef(null);
  const SiteForm = useRef(null);
  
  const getFormValue = (attribute) => JobsForm.current.getFieldValue(attribute);
  const setFormValues = (dataObj) => JobsForm.current.setFieldsValue(dataObj);

  const handleSetOptions = (target, newArr) => setOptions(prev => ({...prev, [target]: newArr}));
  const handleSetProject = (newArr) => handleSetOptions("project", newArr);
  const handleSetInventory = (newArr) => handleSetOptions("inventory", newArr);
  const handleSetAdmin = (newArr) => handleSetOptions("projectAdmin", newArr);
  const handleSetJobStatus = (newArr) => handleSetOptions("jobStatus", newArr);
  const handleSetZone = (newArr) => handleSetOptions("zone", newArr);
  const handleSetSite = (newArr) => handleSetOptions("site", newArr);

  const handleUploadList = (newList) => {
    setUploadList(newList);
  }

  ////////////////////////////////////
  ///--- Initialising functions ---///
  ////////////////////////////////////
  const getProjects = async () => {
    service.getProjectList().then(handleSetProject);
    handleProjectChange(getFormValue("project"));
  };

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getProjects();
      service.getJobStatusList().then(handleSetJobStatus);
      service.getManagerList().then(handleSetAdmin);
      setPermission(checkStaff());
    }
    return () => { subscribed = false; }
  }, []);
  
  ////////////////////////////////
  ///--- Callable functions ---///
  ////////////////////////////////
  const getInventories = async (projectId) => {
    const inventoryList = await service.getProjectInventoryList(projectId);
    handleSetInventory(inventoryList);
    setFormValues({
      "projectInventory": inventoryList.length ? inventoryList[0]["value"] : "",
    });
  };

  const getZones = async (projectId) => {
    const zoneList = await service.getZoneList(projectId);
    handleSetZone(zoneList);
    handleZoneChange(zoneList[0]["value"]);
  };

  const getProjectAdmin = async (projectId) => {
    const project = await service.getProjectDetails(projectId);
    setFormValues({
      "projectAdmin": project.projectManagerID,
      "costCentre": project.costCentreID,
      "salesPerson": project.salesPersonID,
    });
  };

  const handleProjectChange = useCallback((selectedProject) => {
    getProjectAdmin(selectedProject);
    getInventories(selectedProject);
    getZones(selectedProject);
    setFormValues({
      "project": selectedProject,
      "jobStatus": 1,
      "zone": null,
      "site": null,
    });
  }, []);

  const handleZoneChange = useCallback(async (selectedZone) => {
    service.getSitesList(getFormValue("project"), selectedZone)
      .then(handleSetSite);
    const zone = await service.getZoneDetails(selectedZone);
    setFormValues({
      "zone": selectedZone,
      "site": null,
      "contact": null,
      "phone": null,
      "address": null,
      "sla": zone.sla,
      "postJobSLA": zone.postJobSLA,
    });
  }, []);

  const handleSiteChange = useCallback(async (selectedSite) => {
    const site = await service.getSiteDetails(selectedSite);
    setAddressObj(createAddressObj(site));
    setFormValues({
      "site": selectedSite,
      "contact": site.contactPerson,
      "phone": site.phone,
      "address": site.address,
      "suburb": site.suburb,
      "state": site.state,
      "postcode": site.postcode,
      "phone2": site.phone2,
      "email": site.email,
    });
  }, []);

  const onFinish = (values) => {
    console.log(values)
    // Convert date and time to mySQL's 'datetime' datatype format
    values.dateReceived = values.dateReceived.format("YYYY-MM-DDTHH:mm:ss"); //Start Date as well
    values.jobTime = moment(values.jobDate).format("YYYY-MM-DDTHH:mm:00");
    values.sla = moment(values.jobDate).add(values.sla, 'days').format("YYYY-MM-DDT00:00:00");
    values.postJobSLA = moment(values.jobDate).add(values.postJobSLA, 'days').format("YYYY-MM-DDT00:00:00");
    values.jobDate = values.jobDate.format("YYYY-MM-DDT00:00:00");

    // axios
    //   .post("Jobs/CreateJob", {...values})
    //   .then((res) => {
    //     props.history.push("/jobs/jobsdashboard");
    //     helper.successAlet(`Job ${res.data} has been created!`);
    //   })
    //   .catch((error) => {
    //     helper.errorAlert(`Failed to update job: ${error.message}`);
    //   });
    console.log(...uploadList);
    axios
      .post("Jobs/JobDocuments", {...uploadList, headers: {"content-type": "application/json"}})
      .then((res) => {
        //Do stuff
      })
      .catch((e) => {console.log("Damn.")});
  };

  const handleCreateSite = (values) => {
    SiteForm.current.setFieldsValue({
      site_Project: getFormValue("project"),
      site_Zone: getFormValue("zone")
    });
    return new Promise((resolve, reject) => {
      setTimeout(createSite(values) ? resolve : reject, 1000);
    }).catch(() => console.log('Oops errors!'));
  };
  const createSite = async (values) => {
    await axios
      .post("Jobs/CreateSite", {...values})
      .then(async(response) => {
        if (!response.data) throw new AxiosError("Invalid Parameters");
        await service.getSitesList(getFormValue("project"), getFormValue("zone"))
          .then(handleSetSite);
        helper.successAlert(`Site has been successfully created!`);
      })
      .catch((error) => {
        helper.errorAlert(`Failed to create site: ${error.message}`);
      });
  };

  //////////////////////////////////
  ///--- Property Definitions ---///
  //////////////////////////////////
  const uploaderProps = {
    multiple: true,
    listType: "text",
    fileList: uploadList,
    accept: ".txt, .doc, .docx, .xlsx, .xlsm, .pdf, image/*",
    onRemove: (file) => {
      const index = uploadList.findIndex(item => item === file);
      const newList = [...uploadList.slice(0, index), ...uploadList.slice(index + 1)];
      setUploadList(newList);
      return true;
    },
    beforeUpload: (file, fileList) => {
      const ext = [".txt", ".doc", ".docx", ".xlsx", ".xlsm", ".pdf"];
      if (!(file.type.startsWith("image/") || ext.some(e => file.name.endsWith(e)))) {
        helper.errorAlert(`Invalid file detected! One or more of your selected files do not meet the criterion.`, 6);
        return false;
      }
      setUploadList([...uploadList, ...fileList])
      return false;
    }
  };

  return (
    <div style={{margin: "0 auto", width: "90%"}}>
      <Form
        labelCol={{ span: 10 }}
        labelAlign="right"
        wrapperCol={{ span: 14 }}
        layout='horizontal'
        name='newJobForm'
        onFinish={onFinish}
        ref={JobsForm}
        initialValues={{
          dateReceived: moment(), jobDate: moment(), ticketType: "Incident-Hardware", severity: "Moderate",
          project: 36, projectAdmin: 3170, jobStatus: 1, zone: 9, opportunityNo: "207754"
        }}
      >
        <Space direction="vertical" style={{width: "100%"}}>
          <Divider {...prop.dividerProps(true)}>Create New Job</Divider>
          {/* Upper Form */}
          <Row justify="space-between" {...prop.borderStyle(true)}>
            {/* Column 1 */}
            <Col flex="0 1 330px">
              <Form.Item label="Job Date" name="jobDate" {...prop.formRulesProps()} >
                <DatePicker
                  allowClear={false}
                  format={"YYYY-MM-DD H:mm A"}
                  showTime={{ use12Hours: true, format: "h:mm A" }}
                />
              </Form.Item>
              <Form.Item label="Ticket Type" name="ticketType" {...prop.formRulesProps()} {...prop.hiddenProps()} >
                <Select>
                  {[
                    "Incident-Hardware", "Incident-Non-Hardware",
                    "New Deployment Hardware", "Warranty Request"
                  ].map(ticket => ( <Option key={ticket}>{ticket}</Option> ))}
                </Select>
              </Form.Item>
              <Form.Item label="Severity" name="severity" {...prop.formRulesProps()} >
                <Select>
                  {["Moderate", "High", "Critical"].map(level => (
                    <Option key={level}>{level}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col flex="0 1 330px">
              <Form.Item label="Project" name="project" {...prop.formRulesProps("project")}>
                <Select
                  options={helper.sortItemList(options.project, false)}
                  filterOption = {helper.matchSearch}
                  onChange={(value) => handleProjectChange(value)}
                  {...prop.disabledProps(!permission)}
                />
              </Form.Item>
              <Form.Item label="Project Inventory" name="projectInventory" {...prop.formRulesProps("inventory")}>
                <Select 
                  showSearch
                  options={helper.sortItemList(options.inventory)}
                  filterOption = {helper.matchSearch}
                />
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col flex="0 1 330px" {...prop.hiddenProps()}>
              <Form.Item label="Project Admin" name="projectAdmin" {...prop.formRulesProps("administrator")} >
                <Select
                    showSearch
                    options={helper.sortItemList(options.projectAdmin, false)}
                    filterOption = {helper.matchSearch}
                  />
              </Form.Item>
              <Form.Item label="Status" name="jobStatus"{...prop.formRulesProps("status")} >
                <Select
                  showSearch
                  options={helper.sortItemList(options.jobStatus)}
                  filterOption = {helper.matchSearch}
                />
              </Form.Item>
            </Col>
            {/* Column 3/4 */}
            <Col flex="0 1 375px">
              <div style={{marginLeft:"0.25rem"}}>
                <Tooltip placement="right" title="Add Site">
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    style={{ display:"inline-block", margin:"0.25rem 0.5rem", float:"right" }}
                    onClick={() => { setShowSiteModal(true); SiteForm.current?.resetFields(); }}
                  />
                </Tooltip>    
              </div>
              <Form.Item label="Job Site" name="site" {...prop.formRulesProps("site")}>
                <Select 
                  showSearch
                  options={helper.sortItemList(options.site)}
                  filterOption = {helper.matchSearch}
                  onChange={(value) => handleSiteChange(value)}
                />
              </Form.Item>
              {addressObj}
            </Col>
          </Row>

          {/* Lower Form */}
          <Row gutter={[8, 8]}>
            {/* Job Details */}
            <Col flex="1 1 330px" >
              <div {...prop.borderStyle()}>
                <Divider {...prop.dividerProps()}>Job Details</Divider>
                <div style={{padding: "0 1rem"}}>
                  <Form.Item label="Opportunity No" name="opportunityNo" >
                    <Input {...prop.disabledProps(!permission, "input")} />
                  </Form.Item>
                  <Form.Item label="Client Ref" name="clientRef" ><Input /></Form.Item>
                  <Form.Item label="Client PO" name="clientPO" >
                    <Input.TextArea style={{height:"256px"}}/>
                  </Form.Item>
                </div>
              </div>
            </Col>
            {/* Site Details */}
            <Col flex="1 1 330px">
              <div {...prop.borderStyle()}>
                <Divider {...prop.dividerProps()}>Site Details</Divider>
                <div style={{padding: "0 1rem"}}>
                  <Form.Item label="Zone" name="zone" {...prop.formRulesProps("zone")} {...prop.hiddenProps()}>
                    <Select
                      showSearch
                      options={helper.sortItemList(options.zone)}
                      filterOption = {helper.matchSearch}
                      onChange={(value) => handleZoneChange(value)}
                    />
                  </Form.Item>
                  <Form.Item label="Department" name="department" ><Input /></Form.Item>
                  <Form.Item label="Building" name="building" ><Input /></Form.Item>
                  <Form.Item label="Floor" name="floor" ><Input /></Form.Item>
                  <Form.Item label="Contact Person" name="contact" ><Input /></Form.Item>
                  <Form.Item label="Mobile Phone" name="phone" ><Input /></Form.Item>
                  <Form.Item label="Work Phone" name="phone2" ><Input /></Form.Item>
                  <Form.Item label="Email" name="email" ><Input /></Form.Item>
                </div>
              </div>
            </Col>
            {/* Job Comments */}
            <Col flex="1 1 330px">
              <div {...prop.borderStyle()}>
                <Divider {...prop.dividerProps()}>Job Comments</Divider>
                <div style={{padding: "0 1rem", height:"fit-content"}}>
                  <Form.Item name='workInstructions' wrapperCol={16} >
                    <Input.TextArea placeholder="Work Instructions..." style={{height:"196px"}} />
                  </Form.Item>
                  <Form.Item name="files" wrapperCol={24} style={{height:"fit-content"}}>
                    <div className="uploader-container">
                      <Upload.Dragger {...prop.uploaderProps(uploadList, handleUploadList)}>
                        <p>Drag and drop files here.</p>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Col> 
          </Row>
          
          {/* Hidden Form Items */}
          <div {...prop.hiddenProps()}>
            <Form.Item name="dateReceived"><Input /></Form.Item>
            <Form.Item name="jobTime"><Input /></Form.Item>

            <Form.Item name="costCentre"><Input /></Form.Item>
            <Form.Item name="salesPerson"><Input /></Form.Item>

            <Form.Item name="sla"><Input /></Form.Item>
            <Form.Item name="postJobSLA"><Input /></Form.Item>

            <Form.Item name="address" ><Input /></Form.Item>
            <Form.Item name="suburb"><Input /></Form.Item>
            <Form.Item name="state"><Input /></Form.Item>
            <Form.Item name="postcode"><Input /></Form.Item>
          </div>

          {/* Submit Button */}
          {/* wrapperCol={{ offset: 8, span: 16 }}> */}
          {/* <Form.Item> 
            <Button type="primary" htmlType='button' onClick={() => JobsForm.current.submit()}>Create Job</Button>
          </Form.Item> */}
        </Space>
      </Form>
      <Modal
        title="Add New Site"
        visible={showSiteModal}
        onOk={() => {
          // SiteForm.current.submit();
          setShowSiteModal(false);
        }}
        onCancel={() => setShowSiteModal(false)}
      >
        <Form
          labelCol={{ span: 8 }}
          labelAlign="right"
          wrapperCol={{ span: 14 }}
          onFinish={handleCreateSite}
          ref={SiteForm}
        >
          <Form.Item label="Site Name" name="siteName"><Input/></Form.Item>
          <Form.Item label="Address Line 1" name="address"><Input/></Form.Item>
          <Form.Item label="Address Line 2" name="addressLine2"><Input/></Form.Item>
          <Form.Item label="Suburb" name="suburb"><Input/></Form.Item>
          <Form.Item label="State" name="state"><Input/></Form.Item>
          <Form.Item label="Postcode" name="postcode"><Input/></Form.Item>
          <Form.Item label="Contact Name" name="contactPerson"><Input/></Form.Item>
          <Form.Item label="Mobile Phone" name="phone"><Input/></Form.Item>
          <Form.Item label="Work Phone" name="phone2"><Input/></Form.Item>
          <Form.Item label="Email" name="email"><Input/></Form.Item>

          <div {...prop.hiddenProps()}>
            <Form.Item name="site_Project"><Input/></Form.Item>
            <Form.Item name="site_Zone"><Input/></Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
