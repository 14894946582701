import React, { useEffect, useRef, useState }  from "react";
import { Button, Form, Modal, Space, Table, Tooltip, Upload } from "antd";
import { PlusOutlined, CloudDownloadOutlined, InboxOutlined, MinusOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import * as helper from "../../../../util/helper";
import * as prop from "../../../../util/properties";
import * as service from "../../../../util/services";
import { checkStaff } from "../../../../util/auth";

export default function RazorSOJobDocuments(props) {
  const {jobId, jobNo} = props;
  const [permission, setPermission] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [constFiles, setConstFiles] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
    position: ["none", "none"],
  });
  const AddDocForm = useRef(null);

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
  }
  const handleUploadList = (newList) => {
    setUploadList(newList);
  }

  const getDocuments = async () => {
    const documents = await service.getRazorSOJobDocuments(jobNo);
    var newDataSet = [...constFiles];
    documents.forEach(async (doc) => {
      if (constFiles.some(x => x.id == doc.id)) return;
      newDataSet = [...newDataSet, doc];
      setFiles(newDataSet);
      setConstFiles(newDataSet);
    });
  };

  useEffect(() => {
    var subscribed = true;
    subscribed && getDocuments() && setPermission(checkStaff());
    return () => { subscribed = false; }
  }, [jobId, jobNo]);

  const columns = [
    {
      title: "File",
      dataIndex: "name",
      width: "50%",
      ...prop.cstmColProps("name", constFiles),
      defaultSortOrder: "ascend",
      render: (value) => value.split(".")[0],
    },
    {
      title: "File Size",
      dataIndex: "fileSize",
      width: "15%",
      sorter: (a, b) => a.fileSize - b.fileSize,
      render: (value) => {
        var size = value;
        if (size <= 0) return;
        const recurse = (num) => {
          var count = 0;
          if (num < 1024) return count;
          count += recurse(num / 1024);
          return ++count;
        }
        const iterations = recurse(size);
        return Math.round(size / (1024 ** iterations)) + ["B", "KB", "MB", "GB", "TB"][iterations];
      },
    },
    {
      title: "File Type",
      dataIndex: "name",
      width: "15%",
      ...prop.cstmColProps("name", constFiles, null, true),
      render: (value) => value.split(".")[1],
    },
    {
      title: "Download",
      align: "center",
      width: 120,
      render: (record) => (
        <Button
          type='primary'
          shape='circle'
          icon={<CloudDownloadOutlined />}
          size='medium'
          onClick={() => download(record)}
        />
      ),
    },
    {
      title: "Action",
      align: "center",
      width: 100,
      render: (record) => (
        <Tooltip title="Remove file" placement="right">
          <Button
            type='button'
            shape='circle'
            icon={<MinusOutlined />}
            size='small'
            style={{
              color: "white",
              backgroundColor: "red",
              borderColor: "red",
            }}
            onClick={() => handleRemoveDocument(record)}
          />
        </Tooltip>
      ),
      hidden: !editMode,
    },
  ].filter(col => !col.hidden);

  const download = async (file) => {
    helper.saveBlob(await service.getRazorSODocumentBuffer(file), file.name);
  }

  const downloadAll = () => {
    constFiles.forEach((file) => {
      download(file);
    })
  };

  const handleAddDocuments = async (data) => {
    if (!permission) {
      helper.errorAlert(`You do not have permission to upload files.`);
      return null;
    }
    
    try {
      const formData = new FormData();
      var fileList = data.files.fileList;
      fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      })
      formData.append("orderNo", jobNo);

      const response = await service.uploadRazorSODocuments(formData);
      if (response == null || !(response.length > 0)) throw response;
      helper.successAlert(`Successfully added ${response.length} files!`, 6);

      const updatedFiles = [...constFiles, ...response];
      setFiles(updatedFiles);
      setConstFiles(updatedFiles);
    }
    catch (e) {
      helper.errorAlert(`Failed to upload files: ${e.response.data || e}`, 6);
      console.log(e.response.data || e);
    }
  };

  const handleRemoveDocument = (record) => {
    if (!permission) {
      helper.errorAlert(`You do not have permission to delete files.`);
      return null;
    }
    
    Modal.confirm({
      centered: true,
      title: 'You are about to remove "' + record.name + '" from Job No. ' + jobNo,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        try {
          return new Promise(async (resolve, reject) => {
            var action = await service.removeRazorSOJobDocument(record, jobNo);
            console.log(action);
            setTimeout((action == true) ? resolve : reject, 1000);
          })
          .then(() => {
            const fileIndex = constFiles.findIndex(file => file.id === record.id);
            const finalList = [...constFiles.slice(0, fileIndex), ...constFiles.slice(fileIndex + 1)];
            setFiles(finalList);
            setConstFiles(finalList);
            helper.warningAlert(`${record.name} has been successfully removed!`, 2.5);
          });
        } catch (e) {
          console.log(e);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <Space direction='vertical' style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Space style={{ float: "right" }}>
            {permission && (
              <Tooltip title="Add Documents" placement="top">
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  size="default"
                  onClick={(e) => {setShowSiteModal(true); e.currentTarget.blur();}}
                >Add</Button>
              </Tooltip>
            )}
            <Tooltip title="Download All" placement="top">
              <Button
                type='primary'
                shape='round'
                icon={<CloudDownloadOutlined />}
                size='default'
                onClick={downloadAll}
              >Download</Button>
            </Tooltip>
          </Space>
        </div>
        <Table
          id='documentTable'
          dataSource={files}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{x: "max-content", y: "65vh"}}
          onChange={() => {console.log(files, constFiles)}}
        />
        {permission && (
          <Button
            {...prop.editModeProps(editMode, toggleEditMode, permission, "right")}
          >{editMode ? "Exit" : "Enter"} Edit Mode</Button>
        )}
      </Space>

      {/* Upload Modal */}
      <Modal
        title={"Add Documents to JobNo: " + jobNo}
        visible={showSiteModal}
        onOk={() => {
          AddDocForm.current.submit();
          setUploadList([]);
          setShowSiteModal(false);
        }}
        onCancel={() => {
          setUploadList([]);
          setShowSiteModal(false);
        }}
      >
        <Form
          labelCol={{ span: 8 }}
          labelAlign="right"
          wrapperCol={{ span: 24 }}
          onFinish={handleAddDocuments}
          ref={AddDocForm}
        >
          <Form.Item name="files">
            <Upload.Dragger {...prop.uploaderProps(uploadList, handleUploadList)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{color:"var(--primary-color)"}}/>
              </p>
              <p className="ant-upload-text">Click or drag files to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading unknown or malicious files.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};