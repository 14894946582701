import React from "react";
import { Layout } from "antd";
import SideMenu from "../../components/sandbox/SideMenu";
import TopHeader from "../../components/sandbox/TopHeader";
import "./SsictSandbox.css";
import SandboxRouter from "../../components/sandbox/SandboxRouter";

const { Content, Footer } = Layout;

export default function SsictSandbox() {
  return (
    <Layout>
      <SideMenu />
      <Layout className='site-layout'>
        <TopHeader />
        <Content
          className='site-layout-background'
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflow: "auto",
          }}
        >
          <SandboxRouter />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; {new Date().getFullYear()} Renew IT Group All
          rights reserved
        </Footer>
      </Layout>
    </Layout>
  );
}
