import "./login.css";
import React, { useEffect } from "react";
import { Form, Button, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { AuthService as auth } from "../../util/services";
import { readStorage } from "../../util/auth";

export default function Login(props) {
  useEffect(()=> {
    if (readStorage("token")) props.history.push("/dashboard");
  }, []);

  const onFinish = async (values) => {
    const { Email, Username, Password } = values;
    
    try {
      //New Login method
      auth.Login(Username, Password)
        .then((res) => {
          res && auth.GetRole().then(() => props.history.push("/dashboard"));
        })
        .catch((e) => {
          const text = e.response?.data || "Could not connect to server... Please try again later";
          message.error(text);
        });
      //Old Login method
      // auth.AuthHandshake(Email, Password)
      //   .then(auth.InitiateSession)
      //   .then(auth.ValidateSession)
      //   .then(auth.StartSession)
      //   .then((res) => {
      //     res && props.history.push("/dashboard");
      //   });
    } catch (e) {
      // console.log(e)
      const text = e.response?.data || "Could not connect to server... Please try again later";
      message.error(text);
    }
  };
  return (
    <div>
      <div className="dark-overlay" />
      <div className='formContainer'>
        <div className='loginlogo'>
          <img src='ssict_logo.png' alt='' width='150' />
        </div>
        <br />
        <Form name='normal_login' className='login-form' onFinish={onFinish}>
          <Form.Item
            // name='Email'
            name='Username'
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Username'
              size="large"
            />
          </Form.Item>
          <Form.Item
            name='Password'
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Password'
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              id='logBtn'
              htmlType='submit'
              className='login-form-button'
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
