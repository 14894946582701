export const LoadingReducer = (
  prevState = {
    isLoading: true,
    axiosBacklog: [],
  },
  action
) => {
  let { type, payload } = action;
  let newState = { ...prevState };
  switch (type) {
    case "change_loading":
      newState.isLoading = payload;
      break;
    case "add_axios_backlog":
      if (!prevState.axiosBacklog) {
        prevState.axiosBacklog = [];
      }
      newState.axiosBacklog = [...prevState.axiosBacklog, payload];
      break;
    case "remove_axios_backlog":
      newState.axiosBacklog = prevState.axiosBacklog.filter(x => x !== payload);
      break;
    default:
      break;
  }

  newState.isLoading = newState.axiosBacklog.length > 0;
  return newState;
};
