import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Space, Tooltip } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { getAssets, getItemTypesSet } from "../../../../util/services";
import { cstmColProps } from "../../../../util/properties";
import { exportAssets } from "../../../../util/exporter"; 
import { SearchTable } from "../../../../components";

export default function AssetView(props) {
  const { path, filename } = props;
  const [itemTypeMap, setItemTypeMap] = useState({})
  const [assetsList, setAssetsList] = useState([]);
  const [constAssetsList, setConstAssetsList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
  });
  const handleSetAssetsList = (newData) => {
    setAssetsList(newData);
  };

  const getNewAssets = useCallback(() => {
    getAssets(path).then((assets) => {
      setAssetsList(assets);
      setConstAssetsList(assets);
    });
  }, [path]);

  useEffect(() => {
    var subscribed = true;
    if (subscribed && path) {
      getNewAssets();
      getItemTypesSet().then(setItemTypeMap);
    }
    return () => { subscribed = false; }
  }, [path, getNewAssets]);

  const columns = [
    {
      title: "SSN",
      dataIndex: "ssn",
      ...cstmColProps("ssn", constAssetsList),
      defaultSortOrder: "descend",
      render: (value, record) => {
        return <a href={`/assets/editasset/${record.id}`}>{value}</a>;
      },
    },
    {
      title: "Item Type",
      dataIndex: "itemTypeId",
      ...cstmColProps("itemTypeId", constAssetsList, itemTypeMap),
      render: (value) => { return itemTypeMap[value]; },
    },
    {
      title: "Make",
      dataIndex: "make",
      ...cstmColProps("make", constAssetsList),
    },
    {
      title: "Model",
      dataIndex: "model",
      ...cstmColProps("model", constAssetsList),
    },
    {
      title: "SerialNo",
      dataIndex: "serialNo",
      ...cstmColProps("serialNo", constAssetsList),
    },
    {
      title: "Status",
      dataIndex: "assetStatus",
      ...cstmColProps("assetStatus", constAssetsList),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Space style={{ float: "right" }}>
          <SearchTable
            setData={handleSetAssetsList}
            dataSource={constAssetsList}
          />

          <Tooltip placement="bottom" title="Export Data">
            <Button
              type='primary'
              shape='round'
              icon={<FileExcelOutlined />}
              size='default'
              onClick={() => exportAssets(constAssetsList, filename)}
            />
          </Tooltip>
        </Space>
      </div>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='assetsTable'
          dataSource={assetsList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{y: "58vh"}}
          onChange={handleTableChange}
        />
      </div>
    </Space>
    
  );
}
