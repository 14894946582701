import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Table, Button, Space, Tooltip, Col, Row } from "antd";
import { FileExcelOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { exportRazorJobs } from "../../../../util/razor-exporter";
import { cstmColProps } from "../../../../util/properties";
import { SearchTable, QuickFilterBar } from "../../../../components";
import * as service from "../../../../util/services";
import { errorAlert, saveBlob } from "../../../../util/helper";

export default function RazorJobsDashboard() {
  const [isLoading, setLoading] = useState(true);
  const [jobStatusMap, setJobStatusMap] = useState({});
  const [activeJobsList, setActiveJobsList] = useState([]);
  const [constJobsList, setConstJobsList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
  });

  const handleSetJobsList = (newData) => {
    setActiveJobsList(newData);
  };

  const initJobs = useCallback(() => {
    const startDate = new Date("2022-01-01");
    const endDate = new Date();
    var newJobsList = [];
    while (startDate < endDate) {
      const snapShot = new Date(startDate);
      const y = snapShot.getFullYear();
      const m = snapShot.getMonth();
      const d = (m == 0) ? 1 : snapShot.getDate();
      const firstDay = new Date(y, m, d);
      const lastDay = new Date(y, m + 1, d);

      service.getRazorJobsByDate(firstDay.toISOString(), lastDay.toISOString()).then((jobs) => {
        if (jobs.length === 0) return;
        // console.log(jobs);
        var filteredJobs = jobs.filter((val) => {
          return !newJobsList.some(x => x.id == val.id);
        });

        newJobsList = [...newJobsList, ...filteredJobs];
        setActiveJobsList(newJobsList);
        setConstJobsList(newJobsList);
      });
      startDate.setMonth(m + 1);
      startDate.setDate(d + 1);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      initJobs();
      // Object.keys(jobStatusMap).length === 0 && getJobStatusSet().then(setJobStatusMap);
    }
    return () => { subscribed = false;}
  }, [jobStatusMap, initJobs]);
  
  const downloadJobCAF = useCallback(async (job) => {
    try {
      const [blob, filename] =  await service.getRazorCAF(job.id);
      if (blob.size === 0) throw new Error();
      saveBlob(blob, filename);
    }
    catch (e) {errorAlert(`No CAF available for Job: ${job.orderNo}`)}
  }, []);


  const columns = [
    {
      title: "Collection No",
      dataIndex: "orderNo",
      width: 125,
      ...cstmColProps("orderNo", constJobsList),
      defaultSortOrder: "descend",
      render: (value, record) => {
        return <a href={`/r/jobs/job/collection/${record.id}`}>{value}</a>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 125,
      ...cstmColProps("status", constJobsList,),
      render: (value) => { return value; },
    },
    {
      title: "Date Received",
      dataIndex: "dateCreated",
      width: 150,
      ...cstmColProps("dateCreated", constJobsList),
      render: (value) => { return value ? value.split("T")[0] : value; },
    },
    {
      title: "Client Ref",
      dataIndex: "reference1",
      width: 150,
      ...cstmColProps("reference1", constJobsList),
    },
    {
      title: "Client PO",
      dataIndex: "reference2",
      width: 150,
      ...cstmColProps("reference2", constJobsList),
    },
    {
      title: "Comments",
      dataIndex: "workInstructions",
      width: 300,
      ...cstmColProps("workInstructions", constJobsList, null, true),
      render: (value) => <pre style={{ whiteSpace: 'pre-wrap' }}>{ value?.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, "") }</pre>,
    },
    {
      title: "Notes",
      dataIndex: "reference3",
      width: 250,
      ...cstmColProps("reference3", constJobsList, null, true),
    },
    {
      title: "IsComplete",
      dataIndex: "isReadyToBePriced",
      width: 150,
      ...cstmColProps("isReadyToBePriced", constJobsList),
      render: (value) => { return (value) ? "Completed" : "In Progress"; }
    },
    {
      title: "CAF Download",
      align: "center",
      width: 100,
      render: (record) => (
        <Button
          type='primary'
          shape='circle'
          icon={<CloudDownloadOutlined />}
          size='medium'
          onClick={() => downloadJobCAF(record)}
          hidden={![4,5,6].some((v) =>  v == record.statusId)}
        />
      ),
    },
  ];
  
  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  if (isLoading) {
    return <div style={{textAlign:"center", fontSize:"1.5rem", marginTop:window.innerHeight/2.75}}></div>;
  }
  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <Col>
          {/* <QuickFilterBar
            targets={{
              JobStatus: [{
                "Hide Cancelled and Invoiced Jobs": ["Cancelled", "Invoiced"],
                "Hide Ready to Invoice Jobs": ["Ready to Invoice"],
              }, true],
            }}
            columns={columns}
            setData={handleSetJobsList}
            dataMap={JSON.parse(JSON.stringify(jobStatusMap))}
            dataSource={constJobsList}
          /> */}
        </Col>
        
        <Col style={{ float: "right" }}>
          <Space>
            <SearchTable
              setData={handleSetJobsList}
              // dataMap={jobStatusMap}
              dataSource={constJobsList}
              // dataIndex="statusId"
            />
            <Tooltip placement="bottom" title="Export Data">
              <Button
                type='primary'
                shape='round'
                icon={<FileExcelOutlined />}
                size='default'
                onClick={() => exportRazorJobs(constJobsList, jobStatusMap)}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='jobTable'
          dataSource={activeJobsList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{x: "max-content", y: "65vh"}}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
}
