import { notification } from "antd";

export function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

export function saveByteArray(filename, byte) {
  var blob = new Blob([byte], {type: "application/octet-stream"});
  saveBlob(blob, filename);
};

export function saveBlob(blob, filename) {
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  link.remove();
  window.URL.revokeObjectURL(blob);
}

export function createMap(data) {
  return data.reduce((map, obj) => {
    map[obj.id] = obj.value;
    return map;
  }, {});
}

export function createItemList(data) {
  const output = [];
  [...data].forEach((entry) => {
    output.push({
      label: entry.value,
      value: entry.id,
    });
  });
  return [...output];
};

export function sortItemList(list, value = true, asc = true) {
  const filtered = list.sort((a,b) => 
    value
    ? a.value - b.value
    : a["label"].localeCompare(b["label"])
  );
  return asc ? filtered : filtered.sort(x => -1);
};

export function matchSearch(input, option) {
  return option.label.toLowerCase().includes(input.toLowerCase());
};

export function successAlert(message, duration=4.5) {
  notification.success({
    message,
    duration,
    placement: "top",
  });
}

export function warningAlert(message, duration=4.5) {
  notification.warning({
    message,
    duration,
    placement: "top",
  });
}

export function errorAlert(message, duration=4.5) {
  notification.error({
    message,
    duration,
    placement: "top",
  });
}