import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form, Input, Button, DatePicker, Space, Divider,
  Row, Col, Select, Modal
} from "antd";
import moment from "moment";
import * as service from "../../../../util/services";
import * as helper from "../../../../util/helper";
import * as prop from "../../../../util/properties";
import { checkStaff } from "../../../../util/auth";

const { confirm } = Modal;

const createAddressObj = (site) => {
  return (
    <div style={{width:"100%", display:"flex", verticalAlign:"middle", marginBottom:"1.5rem"}}>
      <div style={{width:"24%", display:"inline-block"}}><label style={{float:"right"}}>Address:</label></div>
      <div style={{width:"75%", display:"inline-block", paddingLeft:"1rem"}}>
        <div>{site.address}, {site.addressLine2 && (site.addressLine2 + ", ")}{site.suburb} {site.state} {site.postcode}</div>
      </div>
    </div>
  );
};

export default function RazorJobDetailsView(props) {
  const { jobId, history } = props;
  const [ jobNo, setJobNo ] = useState("");
  const [ permission, setPermission ] = useState("");
  const [ editMode, setEditMode] = useState(false);
  const [ addressObj, setAddressObj ] = useState(null);
  const JobsForm = useRef(null);

  const getFormValue = (attribute) => JobsForm.current.getFieldValue(attribute);
  const setFormValues = (dataObj) => JobsForm.current.setFieldsValue(dataObj);


  const toggleEditMode = () => {
    setEditMode(prev => !prev);
  }
  
  //--- Initialiser ---//
  const getJob = useCallback(async() => {
    if (typeof jobId === "undefined") return null;
    const job = await service.getRazorJobDetails(jobId)
    // console.log(job)
    setJobNo(job.orderNo);
    
    const customerId = job.customerId;
    const addressId = job.customerAddressId;
    service.getRazorJobSite(customerId, addressId).then((site) => {
      setFormValues({
        "site": site.siteName,
        "address": site.address1,
        "suburb": site.city,
        "state": site.state,
        "postcode": site.postcode,
        "contact": site.contact,
        "phone": site.contactPhone,
        "email": site.contactEmail,
        "fax": site.contactFax,
      })
    })
    
    Object.keys(job).forEach((key) => {
      const value =
        ["date", "time"].some(opt => key.toLowerCase().includes(opt))
        ? moment(job[key], "YYYY-MM-DD H:mm A")
        : job[key];

      switch(key)
      {
        case "workInstructions":
          var val = value.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, "");
          setFormValues({ [key]: val });
          break;
        default:
          setFormValues({ [key]: value });
          break;
      }
    });
  }, [jobId]);
  
  useEffect(() => {
    var subscribed = true;
    if (subscribed && typeof jobId !== "undefined") {
      getJob();
      setPermission(checkStaff())
    }
    return () => { subscribed = false; }
  }, [jobId, getJob]);


  const onFinish = (values) => {
    if (!permission) {
      helper.errorAlert(`You do not have permission to update this job.`);
      return null;
    }
  };

  return (
    <div style={{margin: "0 auto", width: "100%"}}>
      <div style={{ marginTop: "0.5rem" }}>
        <Form
          labelCol={{ span: 6 }}
          labelAlign="right"
          wrapperCol={{ span: 17 }}
          layout='horizontal'
          name='editJobForm'
          onFinish={onFinish}
          ref={JobsForm}
          initialValues={{ JobNo: jobNo}}
        >
          <div {...prop.hiddenProps()}>
            <Form.Item name="id"><Input /></Form.Item>
            <Form.Item name="orderNo"><Input /></Form.Item>
            <Form.Item name="jobDate"><Input /></Form.Item>
            <Form.Item name="address" ><Input /></Form.Item>
            <Form.Item name="fax"><Input /></Form.Item>
          </div>
          <Space direction="vertical" style={{width: "100%"}}>
            <Row justify="space-between" {...prop.borderStyle(true)}>
              <Col flex="0 1 525px">
                <Form.Item label="Job Date" name="dateCreated">
                  <DatePicker
                    allowClear={false}
                    open={false}
                    format={"YYYY-MM-DD H:mm A"}
                    showTime={{ use12Hours: true, format: "h:mm A" }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Status" name="status">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col flex="0 1 525px">
                <Form.Item label="Project" name="customer">
                  <Input disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Job Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    {/* <Form.Item label="Opportunity No" name="reference2"><Input disabled/></Form.Item> */}
                    <Form.Item label="Client Ref" name="reference1"><Input disabled/></Form.Item>
                    <Form.Item label="Client PO" name="reference2"><Input disabled /></Form.Item>
                    <Form.Item label="Internal Comments" name='workInstructions' >
                      <Input.TextArea style={{height:"150px"}} disabled/>
                    </Form.Item>
                    <Form.Item label="Additional Notes" name='reference3' >
                      <Input.TextArea style={{height:"150px"}} disabled/>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Site Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Site" name="site"><Input disabled/></Form.Item>
                    {addressObj}
                    <Form.Item label="Address" name="address"><Input disabled/></Form.Item>
                    <Form.Item label="Contact Name" name="contact"><Input disabled/></Form.Item>
                    <Form.Item label="Contact Phone" name="phone"><Input disabled/></Form.Item>
                    <Form.Item label="Contact Email" name="email"><Input disabled/></Form.Item>
                    <Form.Item label="Contact Fax" name="fax"><Input disabled/></Form.Item>
                    <Form.Item label="Suburb" name="suburb"><Input disabled/></Form.Item>
                    <Form.Item label="State" name="state"><Input disabled/></Form.Item>
                    <Form.Item label="Postcode" name="postcode"><Input disabled/></Form.Item>
                  </div>
                </div>
              </Col>
            </Row>

          </Space>
        </Form>
      </div>
    </div>
  );
}
