import React from "react";
import { PageHeader, Tabs } from "antd";
import AssetView from "../assets/AssetView";
import AssetSummary from "./AssetSummary";

export default function Disposalstock(props) {
  const path = "DisposalAssets";
  const title = "Disposal Stock";
  return (
    <div className="card-container">
      <div style={{margin: "0 auto", width: "90%"}}>
            <Tabs
              type="card"
              defaultActiveKey="2"
              moreIcon={null}
              style={{
                width:"100%"
              }}
              tabBarExtraContent={{
                left: 
                  <PageHeader
                    onBack={() => props.history.push("/dashboard")}
                    title={title}
                    style={{padding:"0 0 1rem 0", display:"inline"}}
                  />
              }}
            >
              <Tabs.TabPane tab="Assets" key="1">
                <AssetView path={path} filename={title} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Summary" key="2">
                <AssetSummary path={path} filename={title} />
              </Tabs.TabPane>
            </Tabs>
        <div style={{ marginTop: "0.5rem" }}></div>
      </div>
    </div>
  );
}
