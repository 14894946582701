import { saveAs } from "file-saver";

export function exportAssets(dataList, filename) {
  const columns = [
    { header: "SSN", key: "ssn", width: 12 },
    { header: "Item Type", key: "itemTypeId", width: 12 },
    { header: "Make", key: "make", width: 12 },
    { header: "Model", key: "model", width: 12 },
    { header: "Serial No", key: "serialNo", width: 12 },
    { header: "Load", key: "loadId", width: 12 },
    { header: "Project", key: "projectId", width: 12 },
    { header: "Warehouse", key: "warehouseId", width: 12 },
    { header: "Location", key: "locationId", width: 12 },
    { header: "Grade", key: "grade", width: 12 },
    { header: "Date Received", key: "dateReceived", width: 12 },
    { header: "Date Tested", key: "dateTested", width: 12 },
    { header: "Appearance", key: "appearance", width: 15 },
    { header: "Completeness", key: "completeness", width: 15 },
    { header: "Condition", key: "condition", width: 12 },
    { header: "Operability", key: "operability", width: 12 },
    { header: "Asset ID", key: "assetID", width: 12 },
    { header: "Asset Status", key: "assetStatusId", width: 12 },
    { header: "Dispatch Box No.", key: "asset_DispatchBoxNo", width: 12 },
    { header: "Pallet No.", key: "palletNo", width: 12 },
    { header: "Job", key: "asset_Job1", width: 12 },
    { header: "Job Bundle", key: "jobBundleId", width: 12 },
    { header: "Buy Price", key: "buyPrice", width: 12 },
    { header: "Expected Sell Price", key: "expectedSellPrice", width: 12 },
    { header: "Sell Price", key: "sellPrice", width: 12 },
    { header: "Qty On-Hand", key: "qtyOnHand", width: 12 },
    { header: "isSerialised", key: "isSerialised", width: 12 },
    { header: "Client Asset Tag", key: "clientAssetTag", width: 12 },
    { header: "Client PO", key: "clientPO", width: 12 },
    { header: "Client Ref", key: "clientRef", width: 12 },
    { header: "Services", key: "services", width: 12 },
    { header: "R2 Grade", key: "r2Grade", width: 12 },
    { header: "Recycle", key: "recycle", width: 12 },
    { header: "Release Date", key: "releaseDate", width: 12 },
    { header: "Reservation Code", key: "reservationCode", width: 15 },
    { header: "Reservation Date", key: "reservationDate", width: 15 },
    { header: "Reservation Comment", key: "reservationComment", width: 15 },
    { header: "Asset Comment", key: "assetComment", width: 12 },
  ];
  createXLSX(columns, dataList, "Assets", filename);
}

export function exportJobs(dataList, map) {
  const columns = [
    { header: "Job No", key: "jobNo", width: 9 },
    { header: "Job Type", key: "jobType", width: 15 },
    { header: "Job Status", key: "job_JobStatus", width: 24 },
    { header: "Project Name", key: "projectName", width: 15 },
    { header: "Date Received", key: "dateReceived", width: 20 },
    { header: "Date Completed", key: "dateCompleted", width: 20 },
    { header: "Client Ref", key: "clientRef", width: 20 },
    { header: "Client PO", key: "clientPO", width: 36 },
    { header: "Opportunity Number", key: "opportunityNumber", width: 20 },
    { header: "Work Instructions", key: "workInstructions", width: 48 },
    { header: "Inventory", key: "job_ProjectInventory", width: 12 },
    { header: "Project", key: "job_Project", width: 12 },
    { header: "CostCentreID", key: "myobCostCentre_CostCentreID", width: 15 },
    { header: "EmployeeID", key: "myobEmployee_EmployeeID", width: 15 },
    { header: "CustomerID", key: "myobCustomer_CustomerID", width: 12 },
    { header: "PostJobSLA", key: "postJobSLA", width: 12 },
    { header: "Start Date", key: "startDate", width: 20 },
    { header: "Severity", key: "severity", width: 9 },
    { header: "ETA", key: "eta", width: 20 },
    { header: "Ticket Type", key: "ticketType", width: 12 },
    { header: "isExport", key: "isExport", width: 9 },
    { header: "Order Comments", key: "orderComments", width: 20 },
  ];
  const exportList = dataList.map(
    ({id, job_JobStatus, ...rest}) => 
    ({job_JobStatus:map[job_JobStatus], ...rest})
  );
  createXLSX(columns, exportList, "Jobs", "Exported Jobs");
}

function createXLSX(columns, exportList, sheetname, filename) {
  const { workbook, worksheet } = initialiseWorkbook(sheetname);
  worksheet.columns = columns;
  addStyling(exportList, worksheet);
  saveFile(".xlsx", filename, workbook);
}

const initialiseWorkbook = (name) => {
  const ExcelJS = require('exceljs');
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);
  return {workbook, worksheet}
}
const addStyling = (list, worksheet) => {
  worksheet.getRow(1).alignment = { vertical: 'middle', wrapText: true };
  worksheet.insertRows(2, list, 'i+');
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF38B75E'}
  };
  worksheet.getRow(1).font = {
    size: 12,
    bold: true
  };
  worksheet.getRow(1).border = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };
}
const saveFile = (saveType, filename, workbook) => {
  const today = new Date();
  const dateString = "[" + today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2) + "]";
  const exportName = [dateString, filename, saveType];

  workbook.xlsx.writeBuffer().then(
    (buffer) => saveAs(new Blob([buffer], { type: "appliation/octet-stream" }), exportName.join(" "))
  );
}