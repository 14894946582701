import React from "react";
import { Layout, Menu, Avatar, Popover } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Logout } from "../../util/services/AuthService";
import { readStorage } from "../../util/auth";

const { Header } = Layout;

function TopHeader(props) {
  const changeCollapsed = () => {
    props.changeCollapsed();
  };
  const UserName = readStorage("name");

  const menu = (
    <Menu>
      <Menu.Item
        danger
        key='1'
        onClick={() => {
          Logout().then(() => props.history.replace("/login"));
        }}
        style={{
          fontWeight: "bold",
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className='site-layout-background' style={{ padding: "0 16px" }}>
      {props.isCollapsed ? (
        <MenuUnfoldOutlined onClick={changeCollapsed} />
      ) : (
        <MenuFoldOutlined onClick={changeCollapsed} />
      )}
      <div style={{ float: "right" }}>
        <b>
          {/* Welcome back */}
          <span style={{ color: "green" }}> {UserName} </span>
        </b>
        <div style={{ display:"inline", padding:"0.25rem", cursor:"pointer" }}>
          <Popover placement="bottomRight" content={menu} trigger="hover" overlayStyle={{ width:"10rem" }}>
            <Avatar size='large' icon={<UserOutlined />} style={{ marginBottom:"0.25rem" }} />
          </Popover>
        </div>
        
      </div>
    </Header>
  );
}

const mapStateToProps = ({ CollapsedReducer: { isCollapsed } }) => {
  return {
    isCollapsed,
  };
};

const mapDispatchToProps = {
  changeCollapsed() {
    return {
      type: "change_collapsed",
    };
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader));
