import { useEffect } from "react";

const Page = ({component:Component, title, ...rest}) => {
  const root = "Customer Connect";

  useEffect(() => {
    document.title = (title) ? (title + " | " + root) : root;
  }, [title]);

  return (
    <Component {...rest} />
  )
};

export default Page;