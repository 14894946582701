export function checkStaff() {
  const permission = readStorage("access");
  return permission
    && (permission === process.env.REACT_APP_USER_EMPLOYEE
    || permission === process.env.REACT_APP_USER_ADMIN);
}

export function validateToken(token) {
  const splitToken = token?.toString()?.split(".");
  return splitToken?.length === 3
}

export function hasExpired(token) {
  const exp = decodeToken(token)["exp"];
  return !(token && Date.now() / 1000 < exp);
}

export function checkRefresh(token) {
  const decoded = decodeToken(token);
  const ttl = decoded["exp"] - decoded["nbf"];
  const timeAlive = Date.now() / 1000 - decoded["nbf"];
  return token && timeAlive > (ttl / 2);
}

export function decodeToken(token) {
  const jose = require("jose");
  return jose.decodeJwt(token);
}

export function readStorage(target) {
  return localStorage.getItem(target);
}

export function setStorage(target, value) {
  localStorage.setItem(target, value);
}

export function clearStorage() {
  localStorage.clear();
}