import axios from "axios";
import { errorAlert } from "./../helper";
import { readStorage } from "../auth";

const errorMsg = (error) => {
    errorAlert(`Could not process request: ${error.response.data || error}`, 5);
}

export async function getRazorCAF(orderId) {
    const res = await axios.get("Razor/InboundOrder/" + orderId + "/attachment", {responseType: "blob"});
    return [ res.data, res.headers["x-content-filename"] ];
}

export async function getRazorDocumentBuffer(file) {
    const res = await axios.post("Razor/InboundOrder/attachment", file, {responseType: "blob"});
    return res.data;
}

export async function getRazorSODocumentBuffer(file) {
    const res = await axios.post("Razor/SalesOrder/attachment", file, {responseType: "blob"});
    return res.data;
}

export async function uploadRazorSODocuments(formData) {
    const res = await axios.post("Razor/SalesOrder/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
}

export async function removeRazorSOJobDocument(file, orderNo) {
    const token = readStorage("token");
    const res = await axios
      .post(`Razor/SalesOrder/${orderNo}/remove`, file, {headers: {token}})
      .catch(errorMsg);
    return res.data;
}