import React, { useCallback, useEffect, useState } from "react";
import { PageHeader, Tabs } from "antd";
import "./jobs.css";
import EditJob from "./EditJob";
import JobDocuments from "./JobDocuments";
import AssetView from "../assets/AssetView";
import { getJobDetails } from "../../../../util/services/DataService";

export default function JobView(props) {
  const [ jobId, setJobId ] = useState();
  const [ jobNo, setJobNo ] = useState();

  const getJob = useCallback(async() => {
    if (typeof jobId !== "undefined")
      getJobDetails(jobId).then((job) => setJobNo(job.jobNo));
  }, [jobId]);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getJob();
      setJobId(props.match.params.id)
    }
    return () => { subscribed = false; }
  }, [getJob, props.match.params.id]);

  return (
    <div className="card-container">
      <div style={{margin: "0 auto", width: "90%"}}>
            <Tabs
              type="card"
              moreIcon={null}
              style={{
                width:"100%"
              }}
              tabBarExtraContent={{
                left: 
                  <PageHeader
                    onBack={() => window.history.back()}
                    title={"Job No: " + jobNo}
                    style={{padding:"0 0 1rem 0", display:"inline"}}
                  />
              }}
            >
              <Tabs.TabPane tab="Details" key="1">
                <EditJob jobId={jobId} history={props.history} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Assets" key="2">
                <AssetView path={jobId + "/JobAssets"} filename={jobNo + " Assets"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Documents" key="3">
                <JobDocuments jobId={jobId} jobNo={jobNo} />
              </Tabs.TabPane>
            </Tabs>
        <div style={{ marginTop: "0.5rem" }}></div>
      </div>
    </div>
  );
}
