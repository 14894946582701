import React, { useCallback } from "react";
import { Input, Tooltip } from "antd";

//Function parent solution
export function SearchTable(props) {
  const { dataMap, dataIndex, dataSource, setData } = props;
  const handleFilterTable = (table, searchIndex) => {
    // Compile table values into array
    const values = [];
    Object.keys(table).forEach((key) => {
      // if(index === 0) return;
      if (table[key] === null || key === "id") {
        values.push("");
      } else if (key === dataIndex) {
        values.push(dataMap[table[key]].toString());
      } else {
        values.push(table[key].toString());
      }
    });

    // Boolean filter
    const checkIncluded = (target) => {
      return target.includes(searchIndex) || target.toLowerCase().includes(searchIndex.toLowerCase());
    };
    return values.some(checkIncluded);
  };

  const handleInputChange = useCallback(
    (searchText) => {
      let filteringList = [...dataSource];
      let filteredList = [];
      const splitSearch = searchText.split(" ");
      splitSearch.forEach((searchItem) => {
        filteredList = [...filteredList,
          ...filteringList.filter((target) =>
            handleFilterTable(target, searchItem)
          )
        ];
      });
      setData(Array.from(new Set(filteredList)));
      // setJobsList(filteringList.filter(target => handleFilterTable(target, searchText)))
    },
    // eslint-disable-next-line
    [setData]
  );

  return (
    <Tooltip title="Search table" placement="left">
      <Input.Search
        style={{ width: 200 }}
        placeholder={`Search...`}
        onSearch={handleInputChange}
        allowClear
      />
    </Tooltip>
  );
}

//Class parent solution
// class SearchTable extends Component {
//     handleInputChange = searchText => {
//         this.props.handleSearchChange(searchText);
//     }

//     render() {
//         return (
//             <Input.Search
//             placeholder={`Search...`}
//             onSearch={this.handleInputChange}
//             allowClear
//         />
//         )
//     }
// }
// export default SearchTable;
