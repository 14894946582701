import { errorAlert } from  "./helper";
import { CustomDropdown } from "../components";

export function hiddenProps(state=false) {
  return { style: { display: state ? "inline-block" : "none" } }
};

export function disabledProps(access, target=null) {
  var props = {
    showSearch:false,
    showArrow:false,
    open:false,
    className:"disabled-field"
  }
  return access && (target === "input" ? { disabled:true } : props)
};

export function dividerProps(heading=false) {
  return {
    orientation: "left",
    style: {
      borderColor: "var(--primary-color-shade15)",
      fontSize: heading ? "1.5rem" : "default",
      margin: heading ? "0" : "1rem 0"
    }
  }
};

export function formRulesProps(target=null) {
  const vowelFirst = "aeiou".split("").some(v => target?.startsWith(v));
  var article = vowelFirst ? "an" : "a";
  return {
    rules: [{
      required: true,
      message: target ? `Please select ${article} ${target}` : "",
    }]
  }
};

export function borderStyle(padding=false) {
  return {
    style: {
      padding: padding ? "1rem 1rem 0 1rem" : 0,
      border: "1px solid",
      borderColor: getComputedStyle(document.body).getPropertyValue('--primary-color-shade15') + "33"
    }
  }
}

export const cstmColProps = (dataIndex, dataList, dataMap=null, searchInput=false) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <CustomDropdown
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        dataIndex={dataIndex}
        dataSource={dataList}
        dataMap={dataMap}
        searchInput={searchInput}
      />
  ),
  onFilter: (value, record) => (dataMap ? dataMap[record[dataIndex]] : record[dataIndex])?.toString().toLowerCase().includes(value.toLowerCase()),
  sorter: (a, b) => dataMap ? dataMap[a[dataIndex]]?.localeCompare(dataMap[b[dataIndex]]) : a[dataIndex]?.localeCompare(b[dataIndex]),
});

export const editModeProps = (editMode, toggleMode, access, pos="none") => {
  return {
    style: {
      float: pos,
      marginLeft: editMode ? "1rem" : "0rem",
      backgroundColor: editMode ? "white" : "var(--primary-color-shade15)",
      color: editMode ? "var(--primary-color-shade15)" : "white",
      borderColor: "var(--primary-color-shade15)",
    },
    type: "default",
    htmlType: "button",
    //shape: "round",
    onClick: (e) => {
      if (!access) {
        errorAlert(`You do not have permission to edit this job.`);
        return null;
      }
      toggleMode();
      e.currentTarget.blur();
    }
  }
}

export const uploaderProps = (uploadList, setUploadList) => {
  return {
    multiple: true,
    listType: "text",
    fileList: uploadList,
    accept: ".txt, .doc, .docx, .xlsx, .xlsm, .pdf, image/*",
    onRemove: (file) => {
      const index = uploadList.findIndex(item => item === file);
      const newList = [...uploadList.slice(0, index), ...uploadList.slice(index + 1)];
      setUploadList(newList);
      return true;
    },
    beforeUpload: (file, fileList) => {
      const ext = [".txt", ".doc", ".docx", ".xlsx", ".xlsm", ".pdf"];
      if (!(file.type.startsWith("image/") || ext.some(e => file.name.endsWith(e)))) {
        errorAlert(`Invalid file detected! One or more of your selected files do not meet the criterion.`, 6);
        return false;
      }
      setUploadList([...uploadList, ...fileList])
      return false;
    }
  }
};