import { saveAs } from "file-saver";

export function exportRazorAssets(dataList, filename) {
  const columns = [
    { header: "SSN", key: "uid", width: 12 },
    { header: "Item Type", key: "attributeSet", width: 12 },
    { header: "Status", key: "status", width: 12 },
    { header: "Make", key: "manufacturer", width: 12 },
    { header: "Model", key: "model", width: 12 },
    { header: "Serial No", key: "serial", width: 12 },
    { header: "Load", key: "lot", width: 12 },
    { header: "Project", key: "customer", width: 12 },
    { header: "Warehouse", key: "warehouseId", width: 12 },
    { header: "Location", key: "location", width: 12 },
    { header: "Grade", key: "grade", width: 12 },
    { header: "Date Received", key: "dateCreated", width: 12 },
    { header: "Date Tested", key: "dateAudited", width: 12 },
    { header: "Condition", key: "condition", width: 12 },
    // { header: "Appearance", key: "appearance", width: 15 },
    // { header: "Completeness", key: "completeness", width: 15 },
    // { header: "Operability", key: "operability", width: 12 },
    // { header: "Services", key: "services", width: 12 },
    { header: "Job", key: "order", width: 12 },
    { header: "Sell Price", key: "price", width: 12 },
    { header: "Qty On-Hand", key: "quantity", width: 12 },
    { header: "Client PO", key: "clientPO", width: 12 },
    { header: "Client Ref", key: "reference", width: 12 },
    { header: "Asset Comment", key: "notes", width: 12 },
  ];
  const exportList = dataList.map(
    ({id, status, ...rest}) => 
    ({status:status.replace("SSICT - ", ""), ...rest})
  );
  createXLSX(columns, exportList, "Assets", filename);
}

export function exportRazorJobs(dataList, map) {
  const columns = [
    { header: "Job No", key: "orderNo", width: 9 },
    { header: "Status", key: "status", width: 24 },
    { header: "Project", key: "customer", width: 15 },
    { header: "Date Received", key: "dateReceivedStart", width: 20 },
    { header: "Is Complete", key: "isReadyToBePriced", width: 20 },
    { header: "Date Completed", key: "dateSettled", width: 20 },
    { header: "Client Ref", key: "reference1", width: 20 },
    { header: "Client PO", key: "reference2", width: 36 },
    { header: "Work Instructions", key: "workInstructions", width: 48 },
    { header: "Additonal Notes", key: "reference3", width: 20 },
    { header: "Start Date", key: "pickupDateStart", width: 20 },
    { header: "Order Comments", key: "internalComments", width: 20 },
  ];
  const exportList = dataList.map(
    ({id, workInstructions, ...rest}) => 
    ({workInstructions:workInstructions.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, ""), ...rest})
  );
  createXLSX(columns, exportList, "Collection Jobs", "Exported Collection Jobs");
}

export function exportRazorSOJobs(dataList, map) {
  const columns = [
    { header: "Job No", key: "salesOrderNo", width: 9 },
    { header: "Job Type", key: "orderTypeId", width: 15 },
    { header: "Status", key: "statusId", width: 24 },
    { header: "Project", key: "customer", width: 15 },
    { header: "Date Created", key: "dateCreated", width: 20 },
    { header: "Reference", key: "reference", width: 36 },
    { header: "PO Number", key: "poNumber", width: 36 },
    { header: "Work Instructions", key: "comments", width: 48 },
    { header: "Start Date", key: "orderDate", width: 20 },
  ];
  const exportList = dataList.map(
    ({id, statusId, comments, ...rest}) => 
    ({statusId:map[statusId], comments:comments.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, ""), ...rest})
  );
  createXLSX(columns, exportList, "Deployment Jobs", "Exported Deployment Jobs");
}

/****************************************************************************************************************/
// Common

function createXLSX(columns, exportList, sheetname, filename) {
  const { workbook, worksheet } = initialiseWorkbook(sheetname);
  worksheet.columns = columns;
  addStyling(exportList, worksheet);
  saveFile(".xlsx", filename, workbook);
}

const initialiseWorkbook = (name) => {
  const ExcelJS = require('exceljs');
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);
  return {workbook, worksheet}
}
const addStyling = (list, worksheet) => {
  worksheet.getRow(1).alignment = { vertical: 'middle', wrapText: true };
  worksheet.insertRows(2, list, 'i+');
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF38B75E'}
  };
  worksheet.getRow(1).font = {
    size: 12,
    bold: true
  };
  worksheet.getRow(1).border = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };
}
const saveFile = (saveType, filename, workbook) => {
  const today = new Date();
  const dateString = "[" + today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2) + "]";
  const exportName = [dateString, filename, saveType];

  workbook.xlsx.writeBuffer().then(
    (buffer) => saveAs(new Blob([buffer], { type: "appliation/octet-stream" }), exportName.join(" "))
  );
}