import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as Echarts from "echarts";
import _ from "lodash";
import "./dashboard.css";
import { Button, Tooltip, Row, Col, Space } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const JobStatusMap = {
  1: "Requested",
  2: "New",
  3: "Scheduled",
  4: "In Progress",
  6: "Ready to Invoice",
  7: "Invoiced",
  9: "Cancelled",
  10: "Work Complete",
  11: "On Hold",
  12: "Onsite Work in Progress",
  13: "Onsite Work Complete",
  14: "Configuration Complete",
  15: "Picked",
  16: "Packed",
  17: "Despatched ",
  18: "Requestor Action Required",
  19: "Responded",
  20: "Resolved",
  22: "Out of Stock ",
  23: "Invoiced (No RC)",
  24: "To Be Built",
  25: "To Be Picked",
  27: "Finance to Investigate",
  28: "Pending Stock (SSICT)",
  29: "Pending Date (Refresh) ",
  30: "Received",
  31: "Closed",
  32: "Recycled",
  33: "Asset Received",
  34: "Asset Partially Received",
  35: "Pending Stock Return (Flexi)",
  36: "Invoiced (Partial RC)",
  37: "Invoiced (RC Received)",
  38: "Awaiting Delivery from CFT",
  39: "Received from CFT",
  40: "Quote Sent to Dell",
  41: "Quote Awaiting Approval",
  42: "Quote Approved - Waiting for Parts",
  43: "Parts Arrived - Awaiting Repair",
  44: "NonWarranty Laptop Repaired",
  45: "Laptop Returned to CFT",
  46: "Pending Dispatch",
  47: "Imaged",
};

const updateGraphWidth = () => {
  var graphElems = document.getElementsByClassName("graphItem");
  for (var elem=0; elem < graphElems.length; elem++){
    graphElems[elem].style.width = (window.innerWidth < 1500) ? window.innerWidth/1.25 + "px" : window.innerWidth/2.5 + "px";
  }
}

export default function Dashboard() {
  const [pieChart, setPieChart] = useState(null);
  const barRef = useRef();
  const pieRef = useRef();
  useEffect(() => {
    axios.get("/Jobs/Jobs").then((res) => {
      renderBarView(_.groupBy(res.data, (item) => item.job_JobStatus));
      renderPieView(_.groupBy(res.data, (item) => item.job_JobStatus));
    });
    return () => {
      window.onresize = null;
    };
    // eslint-disable-next-line
  }, []);

  const renderBarView = (obj) => {
    var myChart = Echarts.init(barRef.current);
    var dataX = Object.keys(obj).map((item) => {
      return JobStatusMap[item];
    });
    var option = {
      title: {
        // text: "Job Status Statistics",
      },
      tooltip: {},
      legend: {
        data: ["Job Status"],
      },
      xAxis: {
        axisLabel: { rotate: "45" },
        data: dataX,
      },
      yAxis: {
        miniInterval: 1,
      },
      series: [
        {
          //name: "Job Status",
          type: "bar",
          data: Object.values(obj).map((item) => item.length),
        },
      ],
    };
    option && myChart.setOption(option);
    window.onresize = () => {
      myChart.resize();
      option && myChart.setOption(option);
      updateGraphWidth();
    };
  };

  const renderPieView = (obj) => {
    var list = [];
    for (var i in obj) {
      list.push({
        name: JobStatusMap[i],
        value: obj[i].length,
      });
    }
    var myChart;
    if (!pieChart) {
      myChart = Echarts.init(pieRef.current);
      setPieChart(myChart);
    } else {
      myChart = pieChart;
    }
    var option;

    option = {
      title: {
        text: "",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: false,
        orient: "vertical",
        left: "left",
        tooltip: {
          show: true,
          formatter: (value) => "Click to enable/disable: " + value.name,
        },
        icon: "roundRect",
        selector: [
          {
            type: "all",
            title: "All",
          },
          {
            type: "inverse",
            title: "Invert",
          },
        ],
        selectorLabel: {
          show: true,
          fontWeight: "bold",
          fontSize: 13,
          align: "center",
          verticalAlign: "middle",
          lineHeight: 50,
          borderWidth: 0,
          padding: 10,
          width: 40,
          height: 10,
        },
        selectorItemGap: 40,
        selectorButtonGap: 15,
      },
      series: [
        {
          //name: "Job Status",
          type: "pie",
          left: 0,
          right: 0,
          radius: ["45%", "80%"],
          data: list,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
    window.onresize = () => {
      myChart.resize();
      option && myChart.setOption(option);
      updateGraphWidth();
    };
  };

  const toggleLegends = () => {
    var myChart = pieChart;
    if (myChart) {
      var prevOption = myChart.getOption();
      const state = !prevOption.legend[0].show;
      if (prevOption) {
        var option = {
          legend: {
            show: state,
          },
          graphic: {
            type: "rect",
            shape: { width: 200, height: 1000 },
            scaleY: state ? -1 : 0,
            left: "left",
            top: "bottom",
            z: 5,
            invisible: !state,
            style: { fill: "#FFFFFFFF" },
            keyframeAnimation: [
              {
                duration: 400,
                loop: false,
                keyframes: [
                  {
                    percent: 1,
                    easing: "sinusoidalInOut",
                    scaleY: state ? 0 : -1,
                    style: {
                      fill: "#FFFFFFDF",
                    },
                  },
                ],
              },
            ],
          },
          series: [
            {left: (window.innerWidth < 1250*2) ? (!state ? 0 :"30%") : 0, right: 0,}
          ]
        };
        option && myChart.setOption(option);
      }
    }
  };

  return (
    <Space direction="vertical" align="center" size={"middle"} className='site-card-wrapper' style={{ width:"100%" }}>
      <div style={{ textAlign: "center" }}>
        <span style={{ fontSize: "24px", fontWeight: "700" }}>
          Job Status Statistics
        </span>
      </div>

      <Space direction="horizontal" align="center" size={48} style={{width: "100%", padding: "0px 30px", marginTop: "20px"}} wrap>
        <div className="graphItem" style={{ width: (window.innerWidth < 1500) ? window.innerWidth/1.25 : window.innerWidth/2.5, height: "450px" }}>
          <Tooltip placement='right' title='Filter Pie Graph'>
            <Button
              type='primary'
              shape='circle'
              icon={<FilterOutlined />}
              size='default'
              style={{
                backgroundColor: "#38b85e",
                borderColor: "#38b85e",
              }}
              onClick={toggleLegends}
            />
          </Tooltip>
          <div
            ref={pieRef}
            style={{ width: "100%", height: "400px" }}
          />
        </div>
        <div className="graphItem" style={{ width: (window.innerWidth < 1500) ? window.innerWidth/1.25 : window.innerWidth/2.5, height: "450px" }}>
          <div
            ref={barRef}
            style={{ width: "100%", height: "400px" }}
          />
        </div>
      </Space>
    </Space>
  );
}
