import axios from "axios";

export async function getRazorAttributeSets() {
  const res = await axios.get("Razor/ListItem/attribute-sets");
  return res.data;
};

export async function getRazorSOJobStatusSet() {
  const res = await axios.get("Razor/ListItem/salesorder-status-set");
  return res.data;
};