import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Select, Space, Button, Input } from "antd";

export function CustomDropdown(props) {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters, dataIndex, dataSource, dataMap, searchInput } = props;
    const [ dataList, setDataList ] = useState([]);
    const [ selectedFilters, setSelectedFilters ] = useState([]);
    const [ selectOptions, setSelectOptions ] = useState([]);
    const [ dropdownHeight, setDropdownHeight ] = useState("0rem");

    const handleInputChange = useCallback(
        (inputGroup) => {
            setSelectedFilters([...inputGroup]);
            setSelectedKeys([...inputGroup]);
            setTimeout(() => confirm({closeDropdown: false}), 100);
        },
        [selectedFilters, setSelectedKeys, confirm]
    );
    
    const createOptions = useMemo(() => {
        const options = [];
        let uniqueVal = [];
        dataList.forEach((record) => {
            const recordVal = `${record[dataIndex]}`;
            const optionVal = (!Date.parse(recordVal)) ? recordVal : recordVal.split("T")[0];
            if (!optionVal) return;
            const option = (dataMap) ? dataMap[optionVal] : optionVal;
            if (!(uniqueVal.includes(option.toLowerCase())) && option !== "null"){
                uniqueVal.push(option.toLowerCase());
                options.push({
                    key: option,
                    label: option,
                    value: option
                });
            }
        });
        setSelectOptions(options);
    }, [dataList]);

    useEffect(() => {
        var subscribed = true;
        if (subscribed) {
            setDataList([...dataSource]);
        }
        return () => { subscribed = false; }
    }, [dataSource]);
    
    const selectAll = () => {
        const allOptions = selectOptions.map(option => option.value);
        setSelectedFilters([...allOptions]);
        setSelectedKeys(allOptions);
        setTimeout(() => confirm({closeDropdown: false}), 100);
    };

    const handleReset = () => {
        clearFilters();
        setSelectedFilters([]);
        confirm({closeDropdown: false});
    };
    
    return (
        <div style={{padding: "0.5rem"}}>
            {createOptions}
            <Space direction="vertical">
                {searchInput ?
                    <Input.Search
                        style={{ width: 200 }}
                        placeholder={`Search column...`}
                        value={selectedKeys}
                        onChange={(input) => {
                            setSelectedFilters([input.target.value]);
                            setSelectedKeys([input.target.value]);
                            setTimeout(() => confirm({closeDropdown: false}), 100);
                        }}
                        allowClear
                    />
                    :
                    <Select
                        mode="multiple"
                        style={{width: '190px', marginBottom: dropdownHeight}}
                        dropdownStyle={{boxShadow: 'inset 0px 10px 10px -5px rgba(225,225,225,0.2), inset 0px -10px 10px -5px rgba(225,225,225,0.2)' }}
                        placeholder={"Search in filters..."}
                        value={selectedKeys}
                        options={selectOptions.sort((a,b) => a["value"].localeCompare(b["value"]))}
                        onChange={ (e) => handleInputChange(e) }
                        onDropdownVisibleChange={ (vis) => {setDropdownHeight(vis ? "270px" : "0rem")} }
                        maxTagCount="responsive"
                        allowClear
                        showArrow
                    />
                }
                <Space direction="horizontal" align="center" style={{float: "right"}}>
                    {!searchInput ?
                        <Button
                            type='primary'
                            shape='rect'
                            size='small'
                            onClick={selectAll}
                        >Select All</Button>
                        : null
                    }
                    <Button
                        type='primary'
                        shape='rect'
                        size='small'
                        onClick={handleReset}
                    >Reset</Button>
                    <Button
                        type='primary'
                        shape='rect'
                        size='small'
                        onClick={() => confirm()}
                    >Done</Button>
                </Space>
            </Space>
        </div>
    );
}