import React, { useCallback } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { warningAlert } from "../util/helper";
import { clearStorage, hasExpired, readStorage } from "../util/auth";
import { RefreshToken } from "../util/services/AuthService";
import Login from "../views/login/Login";
import SsictSandbox from "../views/sandbox/SsictSandbox";
import Page from "../components/sandbox/Page";

export default function IndexRouter() {
  const checkToken = useCallback(() => {
    try {
      const token = readStorage("token");
      if (!token) throw "You are not logged in yet. Please log in again to continue.";
      if (hasExpired(token)) throw "Your login session has expired. Please log in again to continue.";
      return RefreshToken(token) && true;
    }
    catch (e) { return clearStorage() && warningAlert(e) && false; }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login'
          render={(props) => <Page {...props} component={Login} title="Login"/>} />
        <Route
          path='/'
          render={() =>
            checkToken()
            ? (<SsictSandbox />)
            : (<Redirect push to='/login' />)
          }
        />
      </Switch>
    </BrowserRouter>
  );
}
