import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form, Input, Button, DatePicker, Space, Divider,
  Row, Col, Select, Modal
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./jobs.css";
import axios from "axios";
import moment from "moment";
import * as service from "../../../../util/services";
import * as helper from "../../../../util/helper";
import * as prop from "../../../../util/properties";
import { checkStaff } from "../../../../util/auth";

const { confirm } = Modal;

const createAddressObj = (site) => {
  return (
    <div style={{width:"100%", display:"flex", verticalAlign:"middle", marginBottom:"1.5rem"}}>
      <div style={{width:"24%", display:"inline-block"}}><label style={{float:"right"}}>Address:</label></div>
      <div style={{width:"75%", display:"inline-block", paddingLeft:"1rem"}}>
        <div>{site.address}, {site.addressLine2 && (site.addressLine2 + ", ")}{site.suburb} {site.state} {site.postcode}</div>
      </div>
    </div>
  );
};

export default function EditJob(props) {
  const { jobId, history } = props;
  const [ jobNo, setJobNo ] = useState("");
  const [ permission, setPermission ] = useState("");
  const [ editMode, setEditMode] = useState(false);
  const [ addressObj, setAddressObj ] = useState(null);
  const [ options, setOptions ] = useState({
    project: [],
    inventory: [],
    jobStatus: [],
    zone: [],
    site: [],
  });
  const JobsForm = useRef(null);

  const getFormValue = (attribute) => JobsForm.current.getFieldValue(attribute);
  const setFormValues = (dataObj) => JobsForm.current.setFieldsValue(dataObj);

  const handleSetOptions = (target, newArr) => setOptions(prev => ({...prev, [target]: newArr}));
  const handleSetProject = (newArr) => handleSetOptions("project", newArr);
  const handleSetInventory = (newArr) => handleSetOptions("inventory", newArr);
  const handleSetJobStatus = (newArr) => handleSetOptions("jobStatus", newArr);
  const handleSetZone = (newArr) => handleSetOptions("zone", newArr);
  const handleSetSite = (newArr) => handleSetOptions("site", newArr);

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
  }
  
  //--- Initialiser ---//
  const getJob = useCallback(async() => {
    if (typeof jobId === "undefined") return null;
    const job = await service.getJobDetails(jobId)
    const projectId = job.project;
    const siteId = job.site;
    setJobNo(job.jobNo);
    
    service.getProjectInventoryList(projectId).then(handleSetInventory);
    service.getZoneList(projectId).then(handleSetZone);
    service.getSiteDetails(siteId).then((site) => {
      const zoneId = site.site_Zone;
      service.getSitesList(projectId, zoneId).then(handleSetSite);
      setAddressObj(createAddressObj(site));
      setFormValues({
        "zone": zoneId,
        "address": site.address,
      });
    });
    
    Object.keys(job).forEach((key) => {
      const value =
        ["date", "time"].some(opt => key.toLowerCase().includes(opt))
        ? moment(job[key], "YYYY-MM-DD H:mm A")
        : job[key];
      setFormValues({ [key]: value });
    });
  }, [jobId]);
  
  useEffect(() => {
    var subscribed = true;
    if (subscribed && typeof jobId !== "undefined") {
      getJob();
      setPermission(checkStaff())
      service.getProjectList().then(handleSetProject);
      service.getJobStatusList().then(handleSetJobStatus);
    }
    return () => { subscribed = false; }
  }, [jobId, getJob]);

  //--- Handlers ---//
  const handleZoneChange = useCallback((selectedZone) => {
    service.getSitesList(getFormValue("project"), selectedZone).then(handleSetSite);
    const zone = service.getZoneDetails(selectedZone);
    setFormValues({
      "zone": selectedZone,
      "site": null,
      "contact": null,
      "phone": null,
      "address": null,
      "sla": zone.sla,
      "postJobSLA": zone.postJobSLA,
    });
  }, []);

  const handleSiteChange = useCallback((selectedSite) => {
    const site = service.getSiteDetails(selectedSite);
    setAddressObj(createAddressObj(site));
    setFormValues({
      "site": selectedSite,
      "contact": site.contactPerson,
      "phone": site.phone,
      "address": site.address,
      "suburb": site.suburb,
      "state": site.state,
      "postcode": site.postcode,
      "phone2": site.phone2,
      "email": site.email,
    });
  }, []);

  const onFinish = (values) => {
    if (!permission) {
      helper.errorAlert(`You do not have permission to update this job.`);
      return null;
    }
    values.jobDate = moment(values.jobTime).format("YYYY-MM-DDT00:00:00");
    values.jobTime = values.jobTime.format("YYYY-MM-DDTHH:mm:00");
    
    confirm({
      centered: true,
      title: 'You are about to update Job No. ' + values.jobNo,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(updateJob(values) ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };

  const updateJob = (values) => {
    axios
      .post("Jobs/UpdateJob", {...values})
      .then((res) => {
        history.push("/jobs/jobsdashboard");
        helper.successAlert(`Job ${res.data} has been updated!`);
        return res.data;
      })
      .catch((error) => {
        helper.errorAlert(`Failed to update job: ${error.message}`);
        return null;
      });
  };

  return (
    <div style={{margin: "0 auto", width: "100%"}}>
      <div style={{ marginTop: "0.5rem" }}>
        <Form
          labelCol={{ span: 6 }}
          labelAlign="right"
          wrapperCol={{ span: 17 }}
          layout='horizontal'
          name='editJobForm'
          onFinish={onFinish}
          ref={JobsForm}
          initialValues={{ JobNo: jobNo}}
        >
          <div {...prop.hiddenProps()}>
            <Form.Item name="id"><Input /></Form.Item>
            <Form.Item name="jobNo"><Input /></Form.Item>
            <Form.Item name="jobDate"><Input /></Form.Item>
            <Form.Item name="address"><Input /></Form.Item>
            <Form.Item name="fax"><Input /></Form.Item>
          </div>
          <Space direction="vertical" style={{width: "100%"}}>
            <Row justify="space-between" {...prop.borderStyle(true)}>
              <Col flex="0 1 525px">
                <Form.Item label="Job Date" name="jobTime" rules={[{ required:true }]}>
                  <DatePicker
                    allowClear={false}
                    format={"YYYY-MM-DD H:mm A"}
                    showTime={{ use12Hours: true, format: "h:mm A" }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Status" name="jobStatus" {...prop.formRulesProps("status")}>
                  <Select
                    showSearch
                    options={helper.sortItemList(options.jobStatus)}
                    filterOption = {helper.matchSearch}
                    onChange={(value) => setFormValues("jobStatus", value)}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
              </Col>
              <Col flex="0 1 525px">
                <Form.Item label="Project" name="project" {...prop.formRulesProps("project")} >
                  <Select
                    options={helper.sortItemList(options.project, false)}
                    filterOption = {helper.matchSearch}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
                <Form.Item label="Project Inventory" name="projectInventory" {...prop.formRulesProps("inventory")}>
                  <Select 
                    showSearch
                    options={helper.sortItemList(options.inventory)}
                    filterOption = {helper.matchSearch}
                    onChange={(value) => setFormValues("projectInventory", value)}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Job Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Opportunity No" name="opportunityNo"><Input disabled/></Form.Item>
                    <Form.Item label="Client Ref" name="clientRef"><Input disabled/></Form.Item>
                    <Form.Item label="Client PO" name="clientPO">
                      <Input.TextArea style={{height:"121px"}} disabled/>
                    </Form.Item>
                    <Form.Item label="Work Instructions" name='workInstructions' >
                      <Input.TextArea style={{height:"269px"}} disabled/>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Site Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Zone" name="zone" {...prop.formRulesProps("zone")} >
                      <Select 
                        showSearch
                        options={helper.sortItemList(options.zone)}
                        filterOption = {helper.matchSearch}
                        onChange={(value) => handleZoneChange(value)}
                        {...prop.disabledProps(true)}
                      />
                    </Form.Item>
                    <Form.Item label="Site" name="site" {...prop.formRulesProps("site")}>
                      <Select 
                        showSearch
                        options={helper.sortItemList(options.site, false)}
                        filterOption = {helper.matchSearch}
                        onChange={(value) => handleSiteChange(value)}
                        {...prop.disabledProps(true)}
                      />
                    </Form.Item>
                    {addressObj}
                    <Form.Item label="Department" name="department"><Input disabled/></Form.Item>
                    <Form.Item label="Building" name="building"><Input disabled/></Form.Item>
                    <Form.Item label="Floor" name="floor"><Input disabled/></Form.Item>
                    <Form.Item label="Contact Name" name="contact"><Input disabled/></Form.Item>
                    <Form.Item label="Mobile Phone" name="phone"><Input disabled/></Form.Item>
                    <Form.Item label="Work Phone" name="phone2"><Input disabled/></Form.Item>
                    <Form.Item label="Email" name="email"><Input disabled/></Form.Item>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {/* <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                {...prop.hiddenProps(editMode)}
              >Update Job</Button>
              <Button
                {...prop.editModeProps(editMode, toggleEditMode, permission)}
              >{editMode ? "Exit" : "Enter"} Edit Mode</Button>
            </Form.Item> */}
          </Space>
        </Form>
      </div>
    </div>
  );
}
