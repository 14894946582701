import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Page from "./Page";
import Dashboard from "../../views/sandbox/dashboard/Dashboard";
import NoPermission from "../../views/sandbox/NoPermission";
/* Archived */
import JobsDashboard from "../../views/sandbox/jms/jobs/JobsDashboard";
import CreateNewJob from "../../views/sandbox/jms/jobs/CreateNewJob";
import JobView from "../../views/sandbox/jms/jobs/JobView";
import EditJob from "../../views/sandbox/jms/jobs/EditJob";
import Newstock from "../../views/sandbox/jms/assets/Newstock";
import Disposalstock from "../../views/sandbox/jms/assets/Disposalstock";
import Preallocated from "../../views/sandbox/jms/assets/Preallocated";
import AssetTag from "../../views/sandbox/jms/assets/Assettag";
import EditAsset from "../../views/sandbox/jms/assets/EditAsset";
/* Current */
import RazorJobsDashboard from "../../views/sandbox/razor/jobs/RazorJobsDashboard";
import RazorJobView from "../../views/sandbox/razor/jobs/RazorJobView";
import RazorAssetDashboard from "../../views/sandbox/razor/assets/RazorAssetDashboard";
import RazorAssetDetailsView from "../../views/sandbox/razor/assets/RazorAssetDetailsView";
import RazorAssetView from "../../views/sandbox/razor/assets/RazorAssetView";
import RazorSOJobsDashboard from "../../views/sandbox/razor/jobs/RazorSOJobsDashboard";
import RazorSOJobView from "../../views/sandbox/razor/jobs/RazorSOJobView";
import RazorBufferAssetView from "../../views/sandbox/razor/assets/RazorBufferAssetView";
import RazorEOLAssetView from "../../views/sandbox/razor/assets/RazorEOLAssetView";
import RazorPreallocatedAssetView from "../../views/sandbox/razor/assets/RazorPreallocatedAssetView";
import RazorReuseAssetView from "../../views/sandbox/razor/assets/RazorReuseAssetView";

function SandboxRouter(props) {
  return (
    <Spin
      size='large'
      spinning={props.isLoading}
      tip={Math.floor(Math.random() * 2) === 0 ? 'Processing your request...' : 'Preparing your workspace...'}
    >
      <Switch>
        <Route path='/dashboard'
          render={(props) => <Page {...props} component={Dashboard} title="Home"/>} />

        {/* Start of Archive */}
        <Route path='/jobs/jobsdashboard'
          render={(props) => <Page {...props} component={JobsDashboard} title="Jobs"/>} />
        <Route path='/jobs/createnewjob'
          render={(props) => <Page {...props} component={CreateNewJob} title="Create Job"/>} />
        <Route path='/jobs/job/:id'
          render={(props) => <Page {...props} component={JobView} title="Job Details"/>} />
        <Route path='/jobs/job/editjob/:id'
          render={(props) => <Page {...props} component={EditJob} title="Job Details"/>} />
        <Route path='/assets/newstock'
          render={(props) => <Page {...props} component={Newstock} title="New Stock"/>} />
        <Route path='/assets/disposalstock'
          render={(props) => <Page {...props} component={Disposalstock} title="Disposal Stock"/>} />
        <Route path='/assets/preallocated'
          render={(props) => <Page {...props} component={Preallocated} title="Preallocated Stock"/>} />
        <Route path='/assets/assettag'
          render={(props) => <Page {...props} component={AssetTag} title="Assettag Stock"/>} />
        <Route path='/assets/editasset/:id'
          render={(props) => <Page {...props} component={EditAsset} title="Asset Details"/>} />
        {/* End of Archive */}

        <Route path='/r/assets/dashboard' 
          render={(props) => <Page {...props} component={RazorAssetDashboard} title="Assets"/>} />
        <Route path='/r/assets/summary' 
          render={(props) => <Page {...props} component={RazorAssetView} title="All Assets"/>} />
        <Route path='/r/assets/buffer/summary' 
          render={(props) => <Page {...props} component={RazorBufferAssetView} title="Buffer Assets"/>} />
        <Route path='/r/assets/eol/summary' 
          render={(props) => <Page {...props} component={RazorEOLAssetView} title="EOL Assets"/>} />
        <Route path='/r/assets/preallocated/summary' 
          render={(props) => <Page {...props} component={RazorPreallocatedAssetView} title="Preallocated Assets"/>} />
        <Route path='/r/assets/reuse/summary' 
          render={(props) => <Page {...props} component={RazorReuseAssetView} title="Reuse Assets"/>} />
        <Route path='/r/assets/asset/:id' 
          render={(props) => <Page {...props} component={RazorAssetDetailsView} title="Asset Details"/>} />
        <Route path='/r/jobs/dashboard/collection' 
          render={(props) => <Page {...props} component={RazorJobsDashboard} title="Collection Jobs"/>} />
        <Route path='/r/jobs/dashboard/deployment' 
          render={(props) => <Page {...props} component={RazorSOJobsDashboard} title="Deployment Jobs"/>} />
        <Route path='/r/jobs/job/collection/:id' 
          render={(props) => <Page {...props} component={RazorJobView} title="Collection Job Details"/>} />
        <Route path='/r/jobs/job/deployment/:id' 
          render={(props) => <Page {...props} component={RazorSOJobView} title="Deployment Job Details"/>} />

        <Redirect from='/' to='/dashboard' exact />
        <Route path='*' component={NoPermission} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = ({ LoadingReducer: { isLoading } }) => ({
  isLoading,
});

export default connect(mapStateToProps)(SandboxRouter);
