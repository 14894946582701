import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Table, Button, Space, Tooltip, Col, Row } from "antd";
import { FileExcelOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { exportRazorSOJobs } from "../../../../util/razor-exporter";
import { cstmColProps } from "../../../../util/properties";
import { SearchTable, QuickFilterBar } from "../../../../components";
import * as service from "../../../../util/services";
import { errorAlert, saveBlob } from "../../../../util/helper";

export default function RazorSOJobsDashboard() {
  const [isLoading, setLoading] = useState(true);
  const [jobStatusMap, setJobStatusMap] = useState({});
  const [activeJobsList, setActiveJobsList] = useState([]);
  const [constJobsList, setConstJobsList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
  });

  const handleSetJobsList = (newData) => {
    setActiveJobsList(newData);
  };

  const initJobs = useCallback(() => {
    const startDate = new Date("2022-01-01");
    const endDate = new Date();
    var newJobsList = [];
    while (startDate < endDate) {
      const snapShot = new Date(startDate);
      const y = snapShot.getFullYear();
      const m = snapShot.getMonth();
      const d = (m == 0) ? 1 : snapShot.getDate();
      const firstDay = new Date(y, m, d);
      const lastDay = new Date(y, m + 1, d);

      service.getRazorSOJobsByDate(firstDay.toISOString(), lastDay.toISOString()).then((jobs) => {
        if (jobs.length === 0) return;
        // console.log(jobs);
        var filteredJobs = jobs.filter((val) => {
          return !newJobsList.some(x => x.id == val.id);
        });

        newJobsList = [...newJobsList, ...filteredJobs];
        setActiveJobsList(newJobsList);
        setConstJobsList(newJobsList);
      });
      startDate.setMonth(m + 1);
      startDate.setDate(d + 1);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      initJobs();
      Object.keys(jobStatusMap).length === 0 && service.getRazorSOJobStatusSet().then((value) => {
        const mappedVals = {};
        value.forEach(v => {
          mappedVals[v.id] = v.name;
        });
        setJobStatusMap(mappedVals);
      });
    }
    return () => { subscribed = false;}
  }, [jobStatusMap, initJobs]);
  
  const downloadJobCAF = useCallback(async (job) => {
    try {
      // const [blob, filename] =  await service.getRazorCAF(job.id);
      // saveBlob(blob, filename);
    }
    catch (e) {errorAlert(`No CAF available for Job: ${job.orderNo}`)}
  }, []);


  const columns = [
    {
      title: "Deployment No",
      dataIndex: "salesOrderNo",
      width: 125,
      ...cstmColProps("salesOrderNo", constJobsList),
      defaultSortOrder: "descend",
      render: (value, record) => {
        return <a href={`/r/jobs/job/deployment/${record.id}`}>{value}</a>;
      },
    },
    {
      title: "Status",
      dataIndex: "statusId",
      width: 125,
      ...cstmColProps("statusId", constJobsList, jobStatusMap),
      render: (value) => { return jobStatusMap[value]; },
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: 150,
      ...cstmColProps("dateCreated", constJobsList),
      render: (value) => { return value ? value.split("T")[0] : value; },
    },
    {
      title: "Reference",
      dataIndex: "poNumber",
      width: 150,
      ...cstmColProps("poNumber", constJobsList),
    },
    // {
    //   title: "ClientRef",
    //   dataIndex: "reference",
    //   width: 150,
    //   ...cstmColProps("reference", constJobsList),
    // },
    // {
    //   title: "OpportunityNo",
    //   dataIndex: "reference2",
    //   width: 150,
    //   ...cstmColProps("reference2", constJobsList),
    // },
    {
      title: "Notes",
      dataIndex: "comments",
      width: 300,
      ...cstmColProps("comments", constJobsList, null, true),
      render: (value) => <pre style={{ whiteSpace: 'pre-wrap' }}>{ value?.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, "") }</pre>

    },
    // {
    //   title: "CAF Download",
    //   align: "center",
    //   width: 100,
    //   render: (record) => (
    //     <Button
    //       type='primary'
    //       shape='circle'
    //       icon={<CloudDownloadOutlined />}
    //       size='medium'
    //       onClick={() => downloadJobCAF(record)}
    //       // hidden={jobStatusMap[record.job_JobStatus] !== "Onsite Work Complete"}
    //     />
    //   ),
    // },
  ];
  
  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  if (isLoading) {
    return <div style={{textAlign:"center", fontSize:"1.5rem", marginTop:window.innerHeight/2.75}}></div>;
  }
  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <Col>
          {/* <QuickFilterBar
            targets={{
              JobStatus: [{
                "Hide Cancelled and Invoiced Jobs": ["Cancelled", "Invoiced"],
                "Hide Ready to Invoice Jobs": ["Ready to Invoice"],
              }, true],
            }}
            columns={columns}
            setData={handleSetJobsList}
            dataMap={JSON.parse(JSON.stringify(jobStatusMap))}
            dataSource={constJobsList}
          /> */}
        </Col>
        
        <Col style={{ float: "right" }}>
          <Space>
            <SearchTable
              setData={handleSetJobsList}
              dataMap={jobStatusMap}
              dataSource={constJobsList}
              dataIndex="statusId"
            />
            <Tooltip placement="bottom" title="Export Data">
              <Button
                type='primary'
                shape='round'
                icon={<FileExcelOutlined />}
                size='default'
                onClick={() => exportRazorSOJobs(constJobsList, jobStatusMap)}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='jobTable'
          dataSource={activeJobsList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{x: "max-content", y: "65vh"}}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
}
