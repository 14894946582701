import axios from "axios";
import { store } from "../redux/store";

axios.defaults.baseURL = process.env.REACT_APP_API;
// axios.defaults.withCredentials = true; //This parameter passes browser cookies to server through header

axios.interceptors.request.use(
  function (config) {
    store.dispatch({
      type: "add_axios_backlog",
      payload: config.url,
    });
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: "remove_axios_backlog",
      payload: response.config.url,
    });
    return response;
  },
  function (error) {
    console.log(error)
    store.dispatch({
      type: "change_loading",
      payload: false,
    });
    return Promise.reject(error);
  }
);
