import axios from "axios";

export async function uploadJobDocuments(formData) {
    const res = await axios.post("Jobs/UploadJobDocuments", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
};

export async function getJobCAF(jobId) {
    const res = await axios.get("Jobs/" + jobId + "/GetJobCAF", {responseType: "blob"});
    return [ res.data, res.headers["x-content-filename"] ];
}

export async function getDocumentBuffer(docId) {
    const res = await axios.get("Jobs/" + docId + "/GetDocumentBuffer", {responseType: "blob"});
    return res.data;
}