import React, { useCallback, useEffect, useState } from "react";
import { PageHeader, Tabs } from "antd";
import RazorAssetDashboard from "./RazorAssetDashboard";
import RazorAssetSummary from "./RazorAssetSummary";
import { getRazorAssetsByCondition } from "../../../../util/services";

export default function RazorPreallocatedAssetView(props) {
  const status = 15;
  const title = "Pre-allocated Assets";
  const [assets, setAssets] = useState([]);

  const getAssets = useCallback(() => {
    getRazorAssetsByCondition(status).then((assets) => {
      if (assets.length === 0) return;

      var assetList = [...assets];
      setAssets(assetList);
    });
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getAssets();
    }
    return () => { subscribed = false; }
  }, []);
  
  return (
    <div className="card-container">
      <div style={{margin: "0 auto", width: "90%"}}>
            <Tabs
              type="card"
              defaultActiveKey="2"
              moreIcon={null}
              style={{
                width:"100%"
              }}
              tabBarExtraContent={{
                left: 
                  <PageHeader
                    // onBack={() => props.history.push("/dashboard")}
                    title={title}
                    style={{padding:"0 0 1rem 0", display:"inline"}}
                  />
              }}
            >
              <Tabs.TabPane tab="Assets" key="1">
                <RazorAssetDashboard assets={assets} title={title} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Summary" key="2">
                <RazorAssetSummary assets={assets} title={title} />
              </Tabs.TabPane>
            </Tabs>
        <div style={{ marginTop: "0.5rem" }}></div>
      </div>
    </div>
  );
}
