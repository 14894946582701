import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Space, Button, Tooltip, Switch, Checkbox } from "antd";
import { ReloadOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export function QuickFilterBar(props) {
    const { targets, columns, dataMap, dataSource, setData } = props;
    const [ dataList, setDataList ] = useState([]);
    const [ selectedFilters, setSelectedFilters ] = useState({});
    const [ allFilters, setAllFilters ] = useState({});
    const [ selectObj, setSelectObj ] = useState({});
    const [ dataIndex, setDataIndex ] = useState({});
    const [ isChecked, setIsChecked ] = useState({});
    const [ showFilters, setShowFilters ] = useState(false);

    const initialise = useCallback(() => {
        Object.keys(targets).forEach((title) => {
            const [messageGroup, needMap] = targets[title];
            columns.forEach((column) => { if (column.title === title) { dataIndex[title] = column.dataIndex; } });
            
            const options = [];
            let uniqueVal = []
            dataList.forEach((record) => {
                const recordVal = `${record[dataIndex[title]]}`;
                const optionVal = (!Date.parse(recordVal)) ? recordVal : recordVal.split("T")[0];
                if (!optionVal) return;
                const option = (needMap) ? dataMap[optionVal] : optionVal;
                if (!(uniqueVal.includes(option.toLowerCase())) && option !== "null"){
                    uniqueVal.push(option.toLowerCase());
                    options.push(option);
                }
            });

            if (options.length > 0) {
                if (!selectedFilters[title]) { selectedFilters[title] = {} };
                allFilters[title] = options;
                Object.keys(messageGroup).forEach((message) =>{
                    selectObj[message] = [
                        <Checkbox
                            checked={isChecked[message]}
                            onChange={(e) => {
                                const currState = e.target.checked;
                                isChecked[message] = currState;
                                handleInputChange(currState ? messageGroup[message] : [], message, title);
                            }}
                        >
                            {message}
                        </Checkbox>
                    ];
                });
            }
        });
    }, [dataMap, dataSource]);

    useEffect(() => {
        var subscribed = true;
        if (subscribed && dataSource.length > 0) {
            setDataList([...dataSource]);
            initialise();
        }
        return () => { subscribed = false; }
    }, [initialise]);

    const handleFilterByColumn = (record, searchIndex, title) => {
        const values = [];
        Object.keys(record).forEach((key) => {
            const value = record[key];
            if (value === null || key === "id") {
                values.push("");
            }
            else if (key === dataIndex[title]) {
                const pushVal = (targets[title].slice(-1)[0]) ? dataMap[value] : value;
                values.push(pushVal.toString());
            }
        });
        const checkIncluded = (target) => {
            if (!Date.parse(searchIndex)) {
                return target.toLowerCase() === searchIndex.toLowerCase();
            }
            else {
                return target.includes(searchIndex) || target.toLowerCase().includes(searchIndex.toLowerCase());
            }
        };
        return values.some(checkIncluded);
    };
    
    const handleInputChange = useCallback(
        (inputGroup, message, title) => {
            selectedFilters[title][message] = inputGroup;

            let filteredList = [];
            Object.keys(selectedFilters).forEach((key) => {
                let notFilters = [];
                Object.keys(selectedFilters[key]).forEach((mes) => {
                    notFilters = [
                        ...notFilters,
                        ...selectedFilters[key][mes]
                    ];
                })

                const finalFilters = allFilters[key].filter((element) => !notFilters.includes(element));
                if (filteredList.length === 0) {
                    finalFilters.forEach((searchItem) => {
                        filteredList = [
                            ...filteredList,
                            ...dataSource.filter((target) =>
                                handleFilterByColumn(target, searchItem, key)
                            )
                        ];
                    });
                }
                else {
                    finalFilters.forEach((searchItem) => {
                        filteredList = [
                            ...filteredList.filter((target) =>
                                handleFilterByColumn(target, searchItem, key)
                            )
                        ];
                    });
                }
            });

            setData(filteredList);
        },
        [setData]
    );

    const handleFilterStates = (checked) => {setShowFilters(checked)};

    const handleReset = () => {
        setData([...dataSource]);
        setSelectedFilters({});
        setIsChecked({});
    };

    const getObjects = () => {
        const objArray = [];
        Object.keys(selectObj).forEach((obj) => { objArray.push(selectObj[obj]) });
        return objArray;
    };
    
    return (
        <Space direction="horizontal" align="center">
            <Tooltip title="Quick Filters">
                <Switch
                    size="default"
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                    onChange={handleFilterStates}/>
            </Tooltip>
            
            {showFilters ?
                <Space direction="vertical">
                    {getObjects()}
                </Space>
                :null
            }

            <Tooltip placement="right" title="Reset">
                <Button
                    type='primary'
                    shape='circle'
                    icon={<ReloadOutlined />}
                    size='small'
                    onClick={handleReset}
                />
            </Tooltip>
        </Space>
    );
}