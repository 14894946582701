import axios from "axios";
import {createItemList, createMap} from "../helper";

export async function getProjectList() {
  const res = await axios.get("ListItem/Projects");
  return createItemList(res.data);
};

export async function getProjectInventoryList(project) {
  const res = await axios.get("ListItem/ProjectInventories/" + project);
  return createItemList(res.data);
};

export async function getLoadsList(project) {
  const res = await axios.get("ListItem/Loads/" + project);
  return createItemList(res.data);
};

export async function getZoneList(project) {
  const res = await axios.get("ListItem/Zones/" + project);
  return createItemList(res.data);
};

export async function getSitesList(project, zone) {
  const res = await axios.get("ListItem/Sites/" + project + "/" + zone);
  return createItemList(res.data);
};

export async function getAssetStatusList() {
  const res = await axios.get("ListItem/AssetStatusSet");
  return createItemList(res.data);
};

export async function getItemTypesList() {
  const res = await axios.get("ListItem/ItemTypes");
  return createItemList(res.data);
};

export async function getJobStatusList() {
  const res = await axios.get("ListItem/JobStatusSet");
  return createItemList(res.data);
};

export async function getManagerList() {
  const res = await axios.get("ListItem/Employees");
  return createItemList(res.data);
};


export async function getJobStatusSet() {
  const res = await axios.get("ListItem/JobStatusSet");
  return createMap(res.data);
};

export async function getItemTypesSet() {
  const res = await axios.get("ListItem/ItemTypes");
  return createMap(res.data)
};
