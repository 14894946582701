import axios from "axios";
import { ValidationError } from "schema-utils";
import {
  checkRefresh, decodeToken, hasExpired,
  readStorage, setStorage, validateToken
} from "../auth";
// import { base64ToArrayBuffer } from "../helper";

export async function Login(Username, Password) {
  const res = await axios.post("Login/Login", { Username, Password });
  const token = res.data;
  if (!validateToken(token)) throw new ValidationError("Invalid credentials");
  return configureSession(token);
}

export async function Logout() {
  const res = await axios.get("Login/Logout", {});
  if (res.status === 200) localStorage.clear();
  return true;
}

export async function GetRole() {
  const token = readStorage("token");
  const res = await axios.get("Login/UserAccess", {headers: {token}});
  setStorage("access", res.data);
  return true;
}

export async function RefreshToken(token) {
  if (!checkRefresh(token)) return false;
  const res = await axios.get("Login/Refresh", {headers: {token}});
  const payload = res.data;
  if (!validateToken(payload)) throw new ValidationError("Invalid Token");
  return configureSession(payload);
}

function configureSession(token) {
  const claims = decodeToken(token)
  if (!claims || hasExpired(token)) return false;
  setStorage("token", claims["sess"]);
  
  if (claims["tct"] === "SessionRefresh") return true;
  setStorage("name", claims["name"]);
  // localStorage.setItem("user", claims["user"]);
  // localStorage.setItem("access", claims["access"]);
  return true;
}

// export async function AuthHandshake(Email, Password) {
//   const res = await axios.post("Login/Authenticate", { Email, Password });
//   const parityToken = res.data.access_token;
//   if (!validateToken(parityToken)) throw new ValidationError("Failed to connect to server");
//   return parityToken;
// }

// export async function InitiateSession(token) {
//   const res = await axios.get("Login/GetSession", { headers: { token } });
//   const accessToken = res.data.key_token;
//   if (!validateToken(accessToken)) throw new ValidationError("Failed to initiate session");
//   return accessToken;
// }

// export async function ValidateSession(token) {
//   const res = await axios.get("Login/ValidateSession", { headers: { token } });
//   if (!res.data) throw new ValidationError("Invalid Session");

//   const jose = require("jose");
//   const claims = jose.decodeJwt(token);

//   const keyBuffer = base64ToArrayBuffer(claims["key"]);
//   // Only works for HTTPS websites, won't work for AWS's own webpage
//   const requestToken = await new jose.SignJWT({
//       user: claims["user"],
//       tct: claims["tct"],
//     })
//     .setProtectedHeader({ typ: "JWT", alg: "HS512" })
//     .setIssuedAt()
//     .setExpirationTime("1m")
//     .sign(new Uint8Array(keyBuffer));
  
//   return requestToken;
// }
              
// export async function StartSession(token) {
//   const res = await axios.get("Login/StartSession", { headers: { token } });
//   const sessToken = res.data.session_token;
//   if (!validateToken(sessToken)) throw new ValidationError("Invalid Token");
//   return configureSession(sessToken);
// }