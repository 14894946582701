import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Table, Button, Space, Tooltip, Col, Row } from "antd";
import { FileExcelOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { exportJobs } from "../../../../util/exporter";
import { cstmColProps } from "../../../../util/properties";
import { SearchTable, QuickFilterBar } from "../../../../components";
import { getJobs, getJobStatusSet, getJobCAF } from "../../../../util/services";
import { errorAlert, saveBlob } from "../../../../util/helper";

export default function JobsDashboard() {
  const [isLoading, setLoading] = useState(true);
  const [jobStatusMap, setJobStatusMap] = useState({});
  const [activeJobsList, setActiveJobsList] = useState([]);
  const [constJobsList, setConstJobsList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
  });

  const handleSetJobsList = (newData) => {
    setActiveJobsList(newData);
  };

  const initJobs = useCallback(() => {
    getJobs().then((data) => {
      setActiveJobsList(data);
      setConstJobsList(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      initJobs();
      Object.keys(jobStatusMap).length === 0 && getJobStatusSet().then(setJobStatusMap);
    }
    return () => { subscribed = false;}
  }, [jobStatusMap, initJobs]);
  
  const downloadJobCAF = useCallback(async (job) => {
    try {
      const [blob, filename] =  await getJobCAF(job.id);
      saveBlob(blob, filename);
    }
    catch (e) {errorAlert(`No CAF available for Job: ${job.jobNo}`)}
  }, []);


  const columns = [
    {
      title: "JobNo",
      dataIndex: "jobNo",
      width: 100,
      ...cstmColProps("jobNo", constJobsList),
      defaultSortOrder: "descend",
      render: (value, record) => {
        return <a href={`/jobs/job/${record.id}`}>{value}</a>;
      },
    },
    {
      title: "JobStatus",
      dataIndex: "job_JobStatus",
      width: 125,
      ...cstmColProps("job_JobStatus", constJobsList, jobStatusMap),
      render: (value) => { return jobStatusMap[value]; },
    },
    {
      title: "DateReceived",
      dataIndex: "dateReceived",
      width: 150,
      ...cstmColProps("dateReceived", constJobsList),
      render: (value) => { return value ? value.split("T")[0] : value; },
    },
    {
      title: "ClientPO",
      dataIndex: "clientPO",
      width: 300,
      ...cstmColProps("clientPO", constJobsList),
    },
    {
      title: "ClientRef",
      dataIndex: "clientRef",
      width: 150,
      ...cstmColProps("clientRef",constJobsList),
    },
    {
      title: "OpportunityNo",
      dataIndex: "opportunityNumber",
      width: 150,
      ...cstmColProps("opportunityNumber",constJobsList),
    },
    {
      title: "WorkInstructions",
      dataIndex: "workInstructions",
      width: 350,
      ...cstmColProps("workInstructions", constJobsList, null, true),
    },
    {
      title: "CAF Download",
      align: "center",
      width: 100,
      render: (record) => (
        <Button
          type='primary'
          shape='circle'
          icon={<CloudDownloadOutlined />}
          size='medium'
          onClick={() => downloadJobCAF(record)}
          hidden={jobStatusMap[record.job_JobStatus] !== "Onsite Work Complete"}
        />
      ),
    },
  ];
  
  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  if (isLoading) {
    return <div style={{textAlign:"center", fontSize:"1.5rem", marginTop:window.innerHeight/2.75}}></div>;
  }
  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <Col>
          <QuickFilterBar
            targets={{
              JobStatus: [{
                "Hide Cancelled and Invoiced Jobs": ["Cancelled", "Invoiced"],
                "Hide Ready to Invoice Jobs": ["Ready to Invoice"],
              }, true],
            }}
            columns={columns}
            setData={handleSetJobsList}
            dataMap={JSON.parse(JSON.stringify(jobStatusMap))}
            dataSource={constJobsList}
          />
        </Col>
        
        <Col style={{ float: "right" }}>
          <Space>
            <SearchTable
              setData={handleSetJobsList}
              dataMap={jobStatusMap}
              dataSource={constJobsList}
              dataIndex="job_JobStatus"
            />
            <Tooltip placement="bottom" title="Export Data">
              <Button
                type='primary'
                shape='round'
                icon={<FileExcelOutlined />}
                size='default'
                onClick={() => exportJobs(constJobsList, jobStatusMap)}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='jobTable'
          dataSource={activeJobsList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{x: "max-content", y: "65vh"}}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
}
