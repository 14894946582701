import React, { useEffect, useState, useMemo } from "react";
import { Table, Button, Space, Tooltip } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { getRazorAssetsByOrder, getRazorAttributeSets, getRazorSOJobAssets } from "../../../../util/services";
import { cstmColProps } from "../../../../util/properties";
import { exportRazorAssets } from "../../../../util/razor-exporter"; 
import { SearchTable } from "../../../../components";

export default function RazorAssetDashboard(props) {
  const { assets, title } = props;
  const { orderId } = props;
  const { jobId, jobNo } = props;
  const [ itemTypeMap, setItemTypeMap ] = useState([]);
  const [ assetsList, setAssetsList ] = useState([]);
  const [ constAssetsList, setConstAssetsList ] = useState([]);
  const [ pagination, setPagination ] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
  });
  const handleSetAssetsList = (newData) => {
    setAssetsList(newData);
  };

  const populateAssets = useMemo(() => {
    if (itemTypeMap.length == 0) return;
    if (assets) {
      setAssetsList([...assets]);
      setConstAssetsList([...assets]);
    }
    else if (orderId) {
      getRazorSOJobAssets(orderId).then((assets) => {
        if (assets.length === 0) return;
        var assetList = [...assets];
        setAssetsList(assetList);
        setConstAssetsList(assetList);
      });
    }
    else if (jobId) {
      getRazorAssetsByOrder(jobId).then((assets) => {
        if (assets.length === 0) return;
        var assetList = [...assets];
        setAssetsList(assetList);
        setConstAssetsList(assetList);
      });
    }
  }, [itemTypeMap, assets]);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getRazorAttributeSets().then(setItemTypeMap);
    }
    return () => { subscribed = false; }
  }, []);

  const columns = [
    {
      title: "SSN",
      dataIndex: "uid",
      ...cstmColProps("uid", constAssetsList),
      defaultSortOrder: "descend",
      render: (value, record) => {
        return <a href={`/r/assets/asset/${record.uid}`}>{value}</a>;
      },
    },
    {
      title: "Item Type",
      dataIndex: "attributeSet",
      ...cstmColProps("attributeSet", constAssetsList),
      render: (value) => { return value; },
    },
    {
      title: "Make",
      dataIndex: "manufacturer",
      ...cstmColProps("manufacturer", constAssetsList),
    },
    {
      title: "Model",
      dataIndex: "model",
      ...cstmColProps("model", constAssetsList),
    },
    {
      title: "SerialNo",
      dataIndex: "serial",
      ...cstmColProps("serial", constAssetsList),
    },
    {
      title: "Status",
      dataIndex: "status",
      ...cstmColProps("status", constAssetsList),
      render: (value) => { return value.replace("SSICT - ", ""); }
    },
    {
      title: "Reference",
      dataIndex: "assetTag",
      ...cstmColProps("assetTag", constAssetsList),
    },
    {
      title: "Comments",
      dataIndex: "notes",
      ...cstmColProps("notes", constAssetsList, null, true),
      render: (value) => { return value?.replaceAll(/(<p>)|(<\/p>)|(\s+)$/g, ""); }
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Space style={{ float: "right" }}>
          <SearchTable
            setData={handleSetAssetsList}
            dataSource={constAssetsList}
          />

          <Tooltip placement="bottom" title="Export Data">
            <Button
              type='primary'
              shape='round'
              icon={<FileExcelOutlined />}
              size='default'
              onClick={() => exportRazorAssets(constAssetsList, jobNo + title)}
            />
          </Tooltip>
        </Space>
      </div>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='assetsTable'
          dataSource={assetsList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{y: "58vh"}}
          onChange={handleTableChange}
        />
      </div>
      {populateAssets}
    </Space>
  );
}
