import React from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import "./index.css";
import {
  BarChartOutlined,
  GroupOutlined,
  FolderOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

const { Sider } = Layout;
const { SubMenu } = Menu;

function SideMenu(props) {
  const selectKeys = [props.location.pathname];
  const openKeys = !props.isCollapsed ? ["/" + props.location.pathname.split("/")[1]] : null;
  return (
    <Sider trigger={null} collapsible collapsed={props.isCollapsed}>
      <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <div className='logo'>
          <img
            src={
              props.isCollapsed
                ? process.env.PUBLIC_URL + "/Renew IT-E 1.png"
                : process.env.PUBLIC_URL + "/RenewIT_Logo_DarkBackgroundOnly.png"
            }
            alt=''
            style={{ height: "32px" }}
          />
        </div>
        <div style={{ flex: 1, overflow: "auto" }}>
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={selectKeys}
            defaultOpenKeys={openKeys}
          >
            <Menu.Item
              key='/dashboard'
              icon={<BarChartOutlined />}
              onClick={() => {
                props.history.push("/dashboard");
              }}
            >
              Dashboard
            </Menu.Item>
            <SubMenu key='/r/jobs' icon={<GroupOutlined />} title='JOBS'>
              <Menu.Item
                key='/r/jobs/dashboard/collection'
                onClick={() => {
                  props.history.push("/r/jobs/dashboard/collection");
                }}
              >
                Collection Jobs Dashboard
              </Menu.Item>
              <Menu.Item
                key='/r/jobs/dashboard/deployment'
                onClick={() => {
                  props.history.push("/r/jobs/dashboard/deployment");
                }}
              >
                Deployment Jobs Dashboard
              </Menu.Item>
            </SubMenu>
            <SubMenu key='/r/assets' icon={<LaptopOutlined />} title='ASSETS'>
              {/* <Menu.Item
                key='/r/assets/summary'
                onClick={() => {
                  props.history.push("/r/assets/summary");
                }}
              >
                All Assets Summary
              </Menu.Item> */}
              <Menu.Item
                key='/r/assets/buffer/summary'
                onClick={() => {
                  props.history.push("/r/assets/buffer/summary");
                }}
              >
                Buffer Assets Summary
              </Menu.Item>
              <Menu.Item
                key='/r/assets/eol/summary'
                onClick={() => {
                  props.history.push("/r/assets/eol/summary");
                }}
              >
                EOL Assets Summary
              </Menu.Item>
              <Menu.Item
                key='/r/assets/preallocated/summary'
                onClick={() => {
                  props.history.push("/r/assets/preallocated/summary");
                }}
              >
                Preallocated Assets Summary
              </Menu.Item>
              <Menu.Item
                key='/r/assets/reuse/summary'
                onClick={() => {
                  props.history.push("/r/assets/reuse/summary");
                }}
              >
                Reuse Assets Summary
              </Menu.Item>
            </SubMenu>
            
            <SubMenu key='' icon={<FolderOutlined />} title='ARCHIVE'>
              <SubMenu key='/jobs' title='JOBS'>
                <Menu.Item
                  key='/jobs/jobsdashboard'
                  onClick={() => {
                    props.history.push("/jobs/jobsdashboard");
                  }}
                >
                  Jobs Dashboard
                </Menu.Item>
                {/* <Menu.Item
                  key='/jobs/createnewjob'
                  onClick={() => {
                    props.history.push("/jobs/createnewjob");
                  }}
                >
                  Create new job
                </Menu.Item> */}
              </SubMenu>
              <SubMenu key='/assets' title='ASSETS'>
                <Menu.Item
                  key='/assets/newstock'
                  onClick={() => {
                    props.history.push("/assets/newstock");
                  }}
                >
                  New stock
                </Menu.Item>
                <Menu.Item
                  key='/assets/disposalstock'
                  onClick={() => {
                    props.history.push("/assets/disposalstock");
                  }}
                >
                  Disposal stock
                </Menu.Item>
                <Menu.Item
                  key='/assets/preallocated'
                  onClick={() => {
                    props.history.push("/assets/preallocated");
                  }}
                >
                  Pre allocated
                </Menu.Item>
                <Menu.Item
                  key='/assets/assettag'
                  onClick={() => {
                    props.history.push("/assets/assettag");
                  }}
                >
                  Asset Tag
                </Menu.Item>
              </SubMenu>
            </SubMenu>
          </Menu>
        </div>
      </div>
    </Sider>
  );
}

const mapStateToProps = ({ CollapsedReducer: { isCollapsed } }) => ({
  isCollapsed,
});

export default connect(mapStateToProps)(withRouter(SideMenu));
