import axios from "axios";
import { errorAlert } from "./../helper";
import { readStorage } from "../auth";

const errorMsg = (error) => {
  errorAlert(`Could not process request: ${error.response.data || error}`, 5);
}

export async function getAssets(path) {
  const res = await axios.get("Assets/" + path);
  return res.data.sort(x => -1);
};

export async function getAssetDetails(id) {
  const res = await axios.get("Assets/AssetDetails/" + id);
  return res.data;
};

export async function getJobs() {
  const res = await axios.get("Jobs/Jobs");
  return res.data.sort(x => -1);
};

export async function getJobDetails(id) {
  const res = await axios.get("Jobs/GetJobDetails/" + id);
  return res.data;
};

export async function getJobDocuments(id) {
  const res = await axios.get("Jobs/" + id + "/GetJobDocuments");
  return res.data;
};

export async function getDocumentContent(id) {
  const res = await axios.get("Jobs/" + id + "/GetDocumentContent");
  return res.data;
};

export async function getProjectDetails(id) {
  const res = await axios.get("ListItem/Project/" + id);
  return res.data[0];
};

export async function getZoneDetails(id) {
  const res = await axios.get("ListItem/Zone/" + id);
  return res.data[0];
};

export async function getSiteDetails(id) {
  const res = await axios.get("ListItem/Site/" + id);
  return res.data[0];
};


export async function removeJobDocument(jobId, docId) {
  const token = readStorage("token");
  const res = await axios
    .post(`Jobs/${docId}/${jobId}/RemoveJobDocument`, null, {headers: {token}})
    .catch(errorMsg);
  return res.data;
};
