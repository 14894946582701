import React, { useEffect, useState, useMemo } from "react";
import { Table, Button, Space, Tooltip } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { SearchTable } from "../../../../components";
import { cstmColProps } from "../../../../util/properties";
import { exportAssets } from "../../../../util/exporter";
import { getAssets, getItemTypesSet } from "../../../../util/services";

export default function AssetSummary(props) {
  const { path, filename } = props;
  const [constAssetsList, setConstAssetsList] = useState([]);
  const [summaryList, setSummaryList] = useState([]);
  const [constSummaryList, setConstSummaryList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [summaryObj, setSummaryObj] = useState({});
  const [itemTypeMap, setItemTypeMap] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 50, 100],
    position: ["none", "none"],
  });

  const handleSetTableList = (newData) => {
    setSummaryList(newData);
  };

  const populateSummaryList = useMemo(() => {
    // Aggregate Data
    Object.keys(itemTypeMap).forEach((key) => {
      const filteredAssets = constAssetsList.filter(asset => asset.itemTypeId == key);
      if (filteredAssets.length) {
        summaryObj[key] = {};
        filteredAssets.forEach((asset) => {
          summaryObj[key][asset.model]
          ? (summaryObj[key][asset.model]++)
          : (summaryObj[key][asset.model] = 1);
        });
      }
    });
    
    // Create Table Data
    var tempList = [];
    var count = 0;
    Object.keys(summaryObj).forEach((key) => {
      const parentId = count++;
      var total = 0;
      var children = [];
      Object.keys(summaryObj[key]).forEach((m) => {
        total += summaryObj[key][m];
        children.push({
          id: count++,
          itemTypeId: null,
          model: m,
          qty: summaryObj[key][m],
        });
      });
      tempList.push({
        id: parentId,
        itemTypeId: key,
        model: null,
        qty: total,
        children: children,
      });
    });

    tempList.sort((a,b) => a.id - b.id);
    setSummaryList(tempList);
    setConstSummaryList(tempList);
  }, [constAssetsList, itemTypeMap]);

  const getNewAssets = async() => {
    const result = await getAssets(path);
    setConstAssetsList(result);
  };

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getNewAssets();
      getItemTypesSet().then(setItemTypeMap);
    }
    return () => { subscribed = false; }
  }, []);

  const columns = [
    {
      title: "Item Type",
      dataIndex: "itemTypeId",
      render: (value) => { return itemTypeMap[value]; },
      onCell: (record, index) => ({
        colSpan: record.model ? 0 : 2,
      }),
      width: '15%',
    },
    {
      dataIndex: "model",
      defaultSortOrder: "ascend",
      ...cstmColProps("itemTypeId", constSummaryList, itemTypeMap),
      render: (value) => { return <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value}</div>},
      onCell: (record, index) => ({
        colSpan: record.model ? 2 : 0,
      }),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      sorter: (a,b) => a["qty"] - b["qty"],
    }
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination({...newPagination});
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Space style={{ float: "right" }}>
          <SearchTable
            setData={handleSetTableList}
            dataMap={itemTypeMap}
            dataSource={constSummaryList}
            dataIndex="itemTypeId"
          />

          <Tooltip placement="bottom" title="Export Data">
            <Button
              type='primary'
              shape='round'
              icon={<FileExcelOutlined />}
              size='default'
              onClick={() => exportAssets(constAssetsList, filename)}
            />
          </Tooltip>
        </Space>
      </div>

      <div style={{ paddingTop: "10px" }}>
        <Table
          id='summaryTable'
          dataSource={summaryList}
          columns={columns}
          size='small'
          bordered
          pagination={pagination}
          rowKey={(item) => item.id}
          rowSelection={{ ...rowSelection, checkStrictly:false }}
          scroll={{y: "60vh"}}
          onChange={handleTableChange}
          summary={(pageData) => {
            let totalSelected = 0;
            pageData.forEach((row) => {
              if (selectedRowKeys.includes(row.id)) {
                totalSelected += row.qty;
              }
              else {
                row.children.forEach((child) => {
                  selectedRowKeys.includes(child.id) && (totalSelected += child.qty);
                });
              }
            });
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3}>Selected Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{totalSelected}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </div>
      {populateSummaryList}
    </Space>
  );
}
