import axios from "axios";

export async function getRazorAssets() {
  const res = await axios.get("Razor/RazorAssets");
  return res.data.sort(x => -1);
};

export async function getRazorAssetsByOrder(orderId) {
  const res = await axios.get(`Razor/RazorAssets/by-order/${orderId}`);
  return res.data;
};

export async function getRazorAssetsByCondition(conditionId) {
  const res = await axios.get(`Razor/RazorAssets/by-condition/${conditionId}`);
  return res.data;
};

export async function getRazorAssetsByDate(startDate, endDate) {
  const res = await axios.get(`Razor/RazorAssets/by-date?startDate=${startDate}&endDate=${endDate}`);
  return res.data;
};

// export async function getRazorAssetDetails(id) {
//   const res = await axios.get("Razor/RazorAssets/by-id/" + id);
//   return res.data;
// };

export async function getRazorAssetDetails(uid) {
  const res = await axios.get("Razor/RazorAssets/by-uid/" + uid);
  return res.data;
};

export async function getRazorJobs() {
  const res = await axios.get("Razor/InboundOrder");
  return res.data.sort(x => -1);
};

export async function getRazorJobDetails(id) {
  const res = await axios.get("Razor/InboundOrder/by-id/" + id);
  return res.data;
};

export async function getRazorJobsByDate(startDate, endDate) {
  const res = await axios.get(`Razor/InboundOrder/by-date?startDate=${startDate}&endDate=${endDate}`);
  return res.data;
};

export async function getRazorJobDocuments(orderId) {
  const res = await axios.get("Razor/InboundOrder/" + orderId + "/attachments");
  return res.data;
};

export async function getRazorJobSite(customerId, addressId) {
  const res = await axios.get(`Razor/InboundOrder/${customerId}/address/${addressId}`);
  return res.data;
};

export async function getRazorSOJobs() {
  const res = await axios.get("Razor/SalesOrder");
  return res.data.sort(x => -1);
};

export async function getRazorSOJobDetails(id) {
  const res = await axios.get("Razor/SalesOrder/by-id/" + id);
  return res.data;
};

export async function getRazorSOJobsByDate(startDate, endDate) {
  const res = await axios.get(`Razor/SalesOrder/by-date?startDate=${startDate}&endDate=${endDate}`);
  return res.data;
};

export async function getRazorSOJobAssets(orderId) {
  const res = await axios.get("Razor/SalesOrder/assets/" + orderId);
  return res.data;
};

export async function getRazorSOJobDocuments(orderNo) {
  const res = await axios.get("Razor/SalesOrder/" + orderNo + "/attachments");
  return res.data;
};