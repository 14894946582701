import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PageHeader, Tabs } from "antd";
import RazorAssetDashboard from "./RazorAssetDashboard";
import RazorAssetSummary from "./RazorAssetSummary";
import { getRazorAssetsByDate } from "../../../../util/services";

export default function RazorAssetView(props) {
  const title = "All Assets";
  const [assets, setAssets] = useState([]);

  const getAssets = useCallback(() => {
    const startDate = new Date("2022-01-01");
    const endDate = new Date();

    var assetList = [];
    while (startDate < endDate) {
      const snapShot = new Date(startDate);
      const y = snapShot.getFullYear();
      const m = snapShot.getMonth();
      const d = (m == 0) ? 1 : snapShot.getDate();
      const firstDay = new Date(y, m, d);
      const lastDay = new Date(y, m + 1, d);

      getRazorAssetsByDate(firstDay.toISOString(), lastDay.toISOString()).then((assets) => {
        if (assets.length === 0) return;
        var filteredAssets = assets.filter((val) => {
          return !assetList.some(x => x.id == val.id);
        });

        assetList = [...assetList, ...filteredAssets];
        setAssets(assetList);
      });
      startDate.setMonth(m + 1);
      startDate.setDate(d + 1);
    }
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getAssets();
    }
    return () => { subscribed = false; }
  }, []);

  return (
    <div className="card-container">
      <div style={{margin: "0 auto", width: "90%"}}>
            <Tabs
              type="card"
              defaultActiveKey="2"
              moreIcon={null}
              style={{
                width:"100%"
              }}
              tabBarExtraContent={{
                left: 
                  <PageHeader
                    // onBack={() => props.history.push("/dashboard")}
                    title={title}
                    style={{padding:"0 0 1rem 0", display:"inline"}}
                  />
              }}
            >
              <Tabs.TabPane tab="Assets" key="1">
                <RazorAssetDashboard assets={assets} title={title} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Summary" key="2">
                <RazorAssetSummary assets={assets} title={title} />
              </Tabs.TabPane>
            </Tabs>
        <div style={{ marginTop: "0.5rem" }}></div>
      </div>
    </div>
  );
}
