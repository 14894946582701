import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form, Input, Button, DatePicker, Space, Divider,
  Row, Col, Modal, Select, PageHeader
} from "antd";

import moment from "moment";
import * as service from "../../../../util/services";
import * as prop from "../../../../util/properties";


export default function RazorAssetDetailsView(props) {
  const [ ssn, setSSN ] = useState();
  const AssetsForm = useRef(null);

  const getFormValue = (attribute) => AssetsForm.current.getFieldValue(attribute);
  const setFormValues = (dataObj) => AssetsForm.current.setFieldsValue(dataObj);

  const retrieveAssetData = useCallback(async() => {
    const asset = await service.getRazorAssetDetails(props.match.params.id);
    // console.log(asset);
    setSSN(asset.uid);
    
    // Modify data
    Object.keys(asset).forEach((key) => {
      const value =
        ["date", "time"].some(opt => key.toLowerCase().includes(opt))
        ? moment(asset[key], "YYYY-MM-DD H:mm A")
        : asset[key];

      switch (key) {
        case "status":
          var v = value.replace("SSICT - ", "");
          setFormValues({ [key]: v });
          break;
        case "assetTag":
        case "notes":
          var valueList = value?.split("; ");
          valueList?.forEach((val) => {
            var [k, v] = val.split(":");
            if (v) {
              k = k.replaceAll(/\s+/g, '').toLowerCase();
              v = v.trim().replaceAll(/[\[\]]/g, '');
              setFormValues({ [k]: v });
            }
            else {
              setFormValues({ [key]: k });
            }
          });
          break;
        case "attributes":
          value?.forEach((val) => {
            var key = val.name.replaceAll(/\s+/g, '').toLowerCase();
            setFormValues({ [key]: val.value });
          });
          break;
        default:
          setFormValues({ [key]: value });
          break;
      }
    });
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      retrieveAssetData();
    }
    return () => {
      subscribed = false;
    }
  }, [retrieveAssetData]);

  const onFinish = (values) => {
  };

  return (
    <div style={{margin: "0 auto", width: "90%"}}>
      <PageHeader
        onBack={() => window.history.back()}
        title={"SSN: " + ssn}
        style={{padding:"0 0 1rem 0"}}
      ><Divider {...prop.dividerProps(true)}></Divider></PageHeader>
      <div style={{ marginTop: "0.5rem" }}>
        <Form
          labelCol={{ span: 6 }}
          labelAlign="right"
          wrapperCol={{ span: 17 }}
          layout='horizontal'
          name='editAssetForm'
          onFinish={onFinish}
          ref={AssetsForm}
          initialValues={{ assetNo: ssn}}
        >
          <div {...prop.hiddenProps()}>
            <Form.Item name="id"><Input /></Form.Item>
            <Form.Item name="uid"><Input /></Form.Item>
            <Form.Item name="assetDate"><Input /></Form.Item>
            <Form.Item name="email"><Input /></Form.Item>
            <Form.Item name="fax"><Input /></Form.Item>
          </div>
          <Space direction="vertical" style={{width: "100%"}}>
            <Row justify="space-between" {...prop.borderStyle(true)}>
              <Col flex="0 1 525px">
                <Form.Item label="Date Received" name="dateCreated">
                  <DatePicker
                    allowClear={false}
                    open={false}
                    format={"YYYY-MM-DD H:mm A"}
                    showTime={{ use12Hours: true, format: "h:mm A" }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Status" name="status">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col flex="0 1 525px">
                <Form.Item label="Project" name="customer">
                  <Input disabled/>
                </Form.Item>
                <Form.Item label="Load" name="order">
                  <Input disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Asset Data</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Item Type" name="attributeSet">
                      <Input disabled/>
                    </Form.Item>
                    <Form.Item label="Make" name="manufacturer"><Input disabled/></Form.Item>
                    <Form.Item label="Model" name="model"><Input disabled/></Form.Item>
                    <Form.Item label="Serial No" name="serial"><Input placeholder="N/A" disabled/></Form.Item>
                    {/* <Form.Item label="IMEI" name="imei" ><Input placeholder="N/A"/></Form.Item> */}
                    {/* <Form.Item label="Colour" name="colour" ><Input placeholder="N/A"/></Form.Item> */}
                    <Form.Item label="Disk Size" name="memorysize"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Grade" name="grade"><Input placeholder="N/A" disabled/></Form.Item>
                  </div>
                </div>
              </Col>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Asset Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Client Ref" name="clientRef"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Client Asset Tag" name="clientassettag" ><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Asset Tag" name="barcode" ><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Asset Comment" name='notes'>
                      <Input.TextArea style={{height:"200px"}} placeholder="N/A" disabled/>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>

          </Space>
        </Form>
      </div>
    </div>
  );
}
