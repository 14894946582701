import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form, Input,Button, DatePicker, Space, Divider,
  Row, Col, Modal, Select, PageHeader
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./assets.css";
import axios from "axios";
import moment from "moment";
import * as service from "../../../../util/services";
import * as helper from "../../../../util/helper";
import * as prop from "../../../../util/properties";

const { confirm } = Modal;

export default function EditAsset(props) {
  const [ ssn, setSSN ] = useState();
  const [ options, setOptions ] = useState({
    project: [],
    loads: [],
    assetStatus: [],
    itemTypes: [],
  });
  const AssetsForm = useRef(null);

  const getFormValue = (attribute) => AssetsForm.current.getFieldValue(attribute);
  const setFormValues = (dataObj) => AssetsForm.current.setFieldsValue(dataObj);

  const handleSetOptions = (target, newArr) => setOptions(prev => ({...prev, [target]: newArr}));
  const handleSetProject = (newArr) => handleSetOptions("project", newArr);
  const handleSetLoads = (newArr) => handleSetOptions("loads", newArr);
  const handleSetAssetStatus = (newArr) => handleSetOptions("assetStatus", newArr);
  const handleSetItemTypes = (newArr) => handleSetOptions("itemTypes", newArr);
  
  const getAsset = useCallback(async() => {
    const assetEdit = await service.getAssetDetails(props.match.params.id);
    setSSN(assetEdit.ssn);
    Object.keys(assetEdit).forEach((key) => {
      const value =
        ["date", "time"].some(opt => key.toLowerCase().includes(opt))
        ? moment(assetEdit[key], "YYYY-MM-DD H:mm A")
        : assetEdit[key];
      setFormValues({ [key]: value });
    });

    const projectId = assetEdit.projectId;
    service.getLoadsList(projectId).then(handleSetLoads);
    //job
    //location
  }, []);

  useEffect(() => {
    var subscribed = true;
    if (subscribed) {
      getAsset();
      service.getProjectList().then(handleSetProject);
      service.getAssetStatusList().then(handleSetAssetStatus);
      service.getItemTypesList().then(handleSetItemTypes);
    }
    return () => {
      subscribed = false;
    }
  }, [getAsset]);

  const onFinish = (values) => {
    values.dateReceived = values.dateReceived.format("YYYY-MM-DDTHH:mm:00");
  
    confirm({
      centered: true,
      title: 'You are about to update SSN ' + values.ssn,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(updateAsset(values) ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };

  const updateAsset = (values) => {
    axios
      .post("Assets/UpdateAsset/" + getFormValue("id"), {...values})
      .then((res) => {
        props.history.push("/assets/newstock");
        helper.successAlert(`Asset ${res.data} has been updated!`);
        return res.data;
      })
      .catch((error) => {
        helper.errorAlert(`Failed to update asset: ${error.message}`);
        return null;
      });
  };

  return (
    <div style={{margin: "0 auto", width: "90%"}}>
      <PageHeader
        onBack={() => window.history.back()}
        title={"SSN: " + ssn}
        style={{padding:"0 0 1rem 0"}}
      ><Divider {...prop.dividerProps(true)}></Divider></PageHeader>
      <div style={{ marginTop: "0.5rem" }}>
        <Form
          labelCol={{ span: 6 }}
          labelAlign="right"
          wrapperCol={{ span: 17 }}
          layout='horizontal'
          name='editAssetForm'
          onFinish={onFinish}
          ref={AssetsForm}
          initialValues={{ assetNo: ssn}}
        >
          <div {...prop.hiddenProps()}>
            <Form.Item name="id"><Input /></Form.Item>
            <Form.Item name="ssn"><Input /></Form.Item>
            <Form.Item name="assetDate"><Input /></Form.Item>
            <Form.Item name="email"><Input /></Form.Item>
            <Form.Item name="fax"><Input /></Form.Item>
          </div>
          <Space direction="vertical" style={{width: "100%"}}>
            <Row justify="space-between" {...prop.borderStyle(true)}>
              <Col flex="0 1 525px">
                <Form.Item label="Date Received" name="dateReceived" >
                  <DatePicker
                    allowClear={false}
                    open={false}
                    format={"YYYY-MM-DD H:mm A"}
                    showTime={{ use12Hours: true, format: "h:mm A" }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Status" name="assetStatusId" {...prop.formRulesProps("status")} >
                  <Select
                    showSearch
                    options={helper.sortItemList(options.assetStatus)}
                    filterOption = {helper.matchSearch}
                    onChange={(value) => setFormValues("assetStatus", value)}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
              </Col>
              <Col flex="0 1 525px">
                <Form.Item label="Project" name="projectId" {...prop.formRulesProps("project")} >
                  <Select
                    showSearch={false}
                    open={false}
                    options={helper.sortItemList(options.project, false)}
                    filterOption = {helper.matchSearch}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
                <Form.Item label="Load" name="loadId" {...prop.formRulesProps("load")} >
                  <Select 
                    showSearch
                    options={helper.sortItemList(options.loads, true, false)}
                    filterOption = {helper.matchSearch}
                    onChange={(value) => setFormValues("load", value)}
                    {...prop.disabledProps(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Asset Data</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Item Type" name="itemTypeId" {...prop.formRulesProps("item type")} >
                      <Select 
                        showSearch
                        options={helper.sortItemList(options.itemTypes, false)}
                        filterOption = {helper.matchSearch}
                        onChange={(value) => setFormValues("itemTypeId", value)}
                        {...prop.disabledProps(true)}
                      />
                    </Form.Item>
                    <Form.Item label="Make" name="make"><Input disabled/></Form.Item>
                    <Form.Item label="Model" name="model"><Input disabled/></Form.Item>
                    <Form.Item label="Serial No" name="serialNo"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="IMEI" name="imei"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Colour" name="colour"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Disk Size" name="diskSize"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Grade" name="grade"><Input disabled/></Form.Item>
                  </div>
                </div>
              </Col>
              <Col flex="1 1 525px">
                <div {...prop.borderStyle()}>
                  <Divider {...prop.dividerProps()}>Asset Details</Divider>
                  <div style={{padding: "0 1rem"}}>
                    <Form.Item label="Client Ref" name="clientRef"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Client PO" name="clientPO"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Client Asset Tag" name="clientAssetTag"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Asset Tag" name="assetTag"><Input placeholder="N/A" disabled/></Form.Item>
                    <Form.Item label="Asset Comment" name='assetComment'>
                      <Input.TextArea style={{height:"200px"}} placeholder="N/A" disabled/>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            {/* <Form.Item>
              <Button type="primary" htmlType='submit'>Update Asset</Button>
            </Form.Item> */}
          </Space>
        </Form>
      </div>
    </div>
  );
}
